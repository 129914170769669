import axios from "axios"

const STRIPE_BASE_URL="https://app-drflyapi-dev.azurewebsites.net/api/Sessions/"

const StripeApi={

    createSession: async(body)=>{
        try{
            const response = await axios.post(
                STRIPE_BASE_URL+ "CreateSessionV2",body
              );
              return { success: true, data:  response.data };
        } catch(error){
            return { success: false, error: error?.response?.data };
        }
    },
    getSession: async(id)=>{
        try{
            const response = await axios.get(
                STRIPE_BASE_URL+ "?sessionId="+id
              );
              return { success: true, data:  response.data };
        } catch(error){
            return { success: false, error: error?.response?.data };
        }
    }

}
export default StripeApi