import axios from "axios"

const AIRPORTS_BASE_URL="https://app-drflyapi-dev.azurewebsites.net/api/Flights/"

const AirportsApi={

    getAllAirports: async(term)=>{
        try{
            const response = await axios.get(
                AIRPORTS_BASE_URL+ "GetAirports?SubType=AIRPORT&SubType=CITY&Keyword="+term
              );
              return response.data
        } catch(error){
            console.error("Error fetching airports:", error);
        }
    }
}

export default AirportsApi