"use client";

import { ClockIcon, MapPinIcon,XCircleIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect, FC } from "react";
import ClearDataButton from "./ClearDataButton";
import AirportsApi from "apis/AirportsApi";
import { Stack } from "@mui/material";

export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  setFrom?: (value: Airport | null) => void;
  setTo?: (value: Airport | null) => void;
  fromValue?: Airport | null;
  toValue?: Airport | null;
}
export interface Airport {
  id: string;
  name: string;
  iataCode: string;
  // Add other properties if needed
}

const LocationInput: FC<LocationInputProps> = ({
  autoFocus = false,
  placeHolder = "Location",
  desc = "Where are you going?",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  setFrom, // Destructure 'setFrom' from props
  setTo, // Destructure 'setTo' from props
  fromValue,
  toValue,
}) => {
  const storedLocalData = localStorage.getItem("recentSearch");
  const parsedData = storedLocalData ? JSON.parse(storedLocalData) : [];
  const [airportsList, setAirportsList] = useState(
    parsedData.map((airport: any) =>
      setFrom ? airport?.fromValue : airport?.toValue ? airport?.toValue : null
    )
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<Airport | null>(null);
  const [searchTerm, setSearchTerm] = React.useState("");

  const [airports, setAirports] = useState<Airport[]>([]);
  const [showPopover, setShowPopover] = useState(autoFocus);

  useEffect(() => {
    const storedLocalData = localStorage.getItem("boxData");
    const parsedData = storedLocalData ? JSON.parse(storedLocalData) : null;

    const initialFromVAlue = parsedData?.fromValue?.name;
    const initialToValue = parsedData?.toValue?.name;

    if (setFrom) setSearchTerm(initialFromVAlue);
    if (setTo) setSearchTerm(initialToValue);
  }, []);

  const handleGetAirports = async (term: string) => {
    setLoading(true); // Set loading to true when fetching starts
    try {
      const response = await AirportsApi.getAllAirports(term);
      setAirports(response.data);
    } catch (error) {
      // Handle errors
    } finally {
      setLoading(false); // Set loading to false when fetching finishes
    }
  };

  // console.log(airports);
  useEffect(() => {
    if (searchTerm) {
      handleGetAirports(searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelectLocation = (item: Airport) => {
    setValue(item);
    setSearchTerm(item.name);
    setShowPopover(false); 
    if (setFrom) setFrom(item); // Call 'setFrom' if it exists
    if (setTo) setTo(item); // Call 'setTo' if it exists
  };

  const handleRecentSearchRemove = (airport: any) => { 

    const storedLocalData = localStorage.getItem("recentSearch"); 
    const parsedData = storedLocalData ? JSON.parse(storedLocalData) : [];
    const updatedValue = setFrom ? parsedData.filter((item: any) => item.fromValue.iataCode !== airport.iataCode) :parsedData.filter((item: any) => item.toValue.iataCode !== airport.iataCode) ;
    const updatedAirportList =  airportsList.filter((item: any) => item.iataCode !== airport.iataCode); 
    setAirportsList(updatedAirportList)
    localStorage.setItem("recentSearch", JSON.stringify(updatedValue));
    setShowPopover(false);
  };
  
  const renderRecentSearches = () => {
    return (
      <>
        <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
          Recent searches
        </h3>

        <div className="mt-2">
          {airportsList.map(
            (item: any, indx: number) =>
              item && (
                <span
                  onClick={() => handleSelectLocation(item)}
                  key={indx}
                  className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                >
                  <span className="block text-neutral-400">
                    <ClockIcon className="h-4 sm:h-6 w-4 sm:w-6" />
                  </span>
                  <Stack direction={"row"} justifyContent={'space-between'} sx={{width:'100%'}}>   
                  <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                    {item?.address?.cityName}
                  </span>
                  <span
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the parent onClick
                      handleRecentSearchRemove(item); // Function to handle the removal 
                    }}
                    className="block text-neutral-400 ml-auto cursor-pointer"
                  >
                    <XCircleIcon className="h-4 sm:h-6 w-4 sm:w-6" /> 
                  </span>
                  </Stack>
                </span>
              )
          )}
        </div>
      </>
    );
  };

  const renderSearchValue = () => {
    return (
      <>
        {loading ? (
          <div className="  rounded-md p-4 max-w-sm w-full mx-auto">
            Loading...
          </div>
        ) : (
          airports?.map((item: Airport) => (
            <span
              onClick={() => handleSelectLocation(item)}
              key={item.id}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
              </span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                {item.name}
              </span>
            </span>
          ))
        )}
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={searchTerm}
            autoFocus={showPopover}
            onChange={(e) => {
              setSearchTerm(e.currentTarget.value);
            }}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">
              {!!searchTerm ? placeHolder : desc}
            </span>
          </span>
          {searchTerm && showPopover && (
            <ClearDataButton
              onClick={() => {
                setValue(null);
                setSearchTerm("");
                if (setFrom) setFrom(null); // Call 'setFrom' if it exists
                if (setTo) setTo(null); // Call 'setTo' if it exists
              }}
            />
          )}
        </div>
      </div>

      {showPopover && (
        <div
          className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}
        ></div>
      )}

      {showPopover &&
        (searchTerm !== "" && searchTerm !== undefined ? (
          <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
            {renderSearchValue()}
          </div>
        ) : (
          (searchTerm === "" || searchTerm === undefined) &&
          airportsList.length > 0 && (
            <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
              {renderRecentSearches()}
            </div>
          )
        ))}
    </div>
  );
};

export default LocationInput;
