import React, { useEffect, useState } from "react";
import LocationInput from "../LocationInput";
import GuestsInput from "../GuestsInput";
import DatesRangeInput from "../DatesRangeInput";
import { GuestsObject } from "components/HeroSearchForm/type";
import { addDays } from "date-fns";
import converSelectedDateToString from "utils/converSelectedDateToString";
import { useNavigate } from "react-router-dom";
import ButtonSubmit from "../ButtonSubmit";
import { Stack } from "@mui/material";
export interface Airport {
  id: string;
  name: string;
  iataCode: string;
  // Add other properties if needed
}
interface FlightSearchFormProps {
  closeModal:any
}

function formatDate(dateString: Date | null) {
  if (dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
}
const flightClass = [
  {
    name: "ECONOMY",
    title: "Economy",
    href: "##",
  },
  {
    name: "PREMIUM_ECONOMY",
    title: "Premium Economy",
    href: "##",
  },
  {
    name: "BUSINESS",
    title: "Business",
    href: "##",
  },
  {
    name: "FIRST",
    title: "First ",
    href: "##",
  },
];
const FlightSearchForm: React.FC<FlightSearchFormProps> = ({closeModal}) => {
  const navigate = useNavigate();
  const [testtrigger, setTesttrigger] = useState(
    localStorage.getItem("trigger") || 0
  );
  const [trigger, setTrigger] = useState(
    localStorage.getItem("realTrigger") || 0
  );
  const [fromValue, setFromValue] = useState<Airport | null>(null);
  const [toValue, setToValue] = useState<Airport | null>(null);

  //
  const [fieldNameShow, setFieldNameShow] = useState<
    "locationPickup" | "locationDropoff" | "dates" | "guests" | "general"
  >("locationPickup");
  //
  const [locationInputPickUp, setLocationInputPickUp] = useState("");
  const [locationInputDropOff, setLocationInputDropOff] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(addDays(new Date(), 15));

  const [dropOffLocationType, setDropOffLocationType] = useState<
    "return" | "oneWay" | ""
  >("return");
  const [flightClassState, setFlightClassState] = useState("Economy");

  const [guestInput, setGuestInput] = useState<GuestsObject>({
    adults: 1,
    children: 0,
    infants: 0,
  });

  const [formData, setFormData] = useState({
    OriginLocationCode: "",
    DestinationLocationCode: "",
    DepartureDate: "",
    ReturnDate: "",
    Adults: guestInput.adults,
  });

  const handleChangeForm = (name: string, value: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const storedLocalData = localStorage.getItem("boxData");
    const parsedData = storedLocalData ? JSON.parse(storedLocalData) : null;
    if (parsedData) {
      setDropOffLocationType(parsedData.dropOffLocationType || "return");
      setFlightClassState(parsedData.flightClassState.title || flightClass[0]);
      setGuestInput({
        adults: parsedData.guestAdultsInputValue || 1,
        children: parsedData.guestChildrenInputValue || 0,
        infants: parsedData.guestInfantsInputValue || 0,
      });
      setFromValue(parsedData.fromValue || null);
      setToValue(parsedData.toValue || null);
      setStartDate(
        parsedData.departureDate
          ? new Date(parsedData.departureDate)
          : new Date()
      );
      setEndDate(
        parsedData.returnDate
          ? new Date(parsedData.returnDate)
          : addDays(new Date(), 15)
      );
    }
  }, []);

  useEffect(() => {
    if (flightClassState) {
      handleChangeForm("TravelClass", flightClassState.toUpperCase());
    }
    if (guestInput.adults && guestInput.adults > 0) {
      handleChangeForm("Adults", guestInput.adults);
    }
    if (guestInput.children && guestInput.children > 0) {
      handleChangeForm("Children", guestInput.children);
    }
    if (guestInput.infants && guestInput.infants > 0) {
      handleChangeForm("Infants", guestInput.infants);
    }
    if (fromValue) {
      handleChangeForm("OriginLocationCode", fromValue.iataCode);
    } else {
      handleChangeForm("OriginLocationCode", "");
    }
    if (dropOffLocationType) {
      if (dropOffLocationType === "return") {
        const formattedDate = formatDate(addDays(new Date(), 15));
        handleChangeForm("ReturnDate", formattedDate);
      }
    }
    if (toValue) {
      handleChangeForm("DestinationLocationCode", toValue.iataCode);
    } else {
      handleChangeForm("DestinationLocationCode", "");
    }
    if (startDate) {
      console.log("startdte");
      const formattedDate = formatDate(startDate);
      handleChangeForm("DepartureDate", formattedDate);
    } else {
      handleChangeForm("DepartureDate", "");
    }
    if (endDate) {
      if (dropOffLocationType === "oneWay") {
        handleChangeForm("ReturnDate", "");
        //setReturnDate(null);
      } else {
        const formattedDate = formatDate(endDate);

        handleChangeForm("ReturnDate", formattedDate);
      }
    }

    if (fromValue && toValue && startDate) {
      //setsearchBtn(false);
    } else {
      // setsearchBtn(true);
    }
  }, [
    fromValue,
    toValue,
    startDate,
    endDate,
    dropOffLocationType,
    flightClassState,
    guestInput,
  ]);

  const handleFetchFlights = () => {
    console.log("dep", startDate);
    console.log("ret", endDate);
    localStorage.setItem("formData", JSON.stringify(formData));
    localStorage.setItem(
      "boxData",
      JSON.stringify({
        fromValue,
        toValue,
        departureDate: startDate,
        returnDate: endDate,
        dropOffLocationType,
        flightClassState: {
          name: flightClassState.toUpperCase(),
          title: flightClassState,
          href: "##",
        },
        guestAdultsInputValue: guestInput.adults,
        guestChildrenInputValue: guestInput.children,
        guestInfantsInputValue: guestInput.infants,
      })
    );
    //window.location.href = '/listing-flights';
    navigate("/listing-flights");
  };


  const renderInputLocationPickup = () => {
    const isActive = fieldNameShow === "locationPickup";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("locationPickup")}
          >
            <span className="text-neutral-400">Pick up</span>
            <span>{locationInputPickUp || "Location"}</span>
          </button>
        ) : (
          <LocationInput
            headingText="Pick up?"
            defaultValue={locationInputPickUp}
            setFrom={setFromValue}
            fromValue={fromValue}
            onChange={(value) => {
              setLocationInputPickUp(value);
              setFieldNameShow("dates");
            }}
          />
        )}
      </div>
    );
  };

  const renderInputLocationDropoff = () => {
    const isActive = fieldNameShow === "locationDropoff";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("locationDropoff")}
          >
            <span className="text-neutral-400">Drop off</span>
            <span>{locationInputDropOff || "Location"}</span>
          </button>
        ) : (
          <LocationInput
            headingText="Drop off?"
            defaultValue={locationInputDropOff}
            setTo={setToValue}
            toValue={toValue}
            onChange={(value) => {
              setLocationInputDropOff(value);
              setFieldNameShow("dates");
            }}
          />
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4  `}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">When</span>
            <span>
              {startDate
                ? converSelectedDateToString([startDate, endDate])
                : "Add date"}
            </span>
          </button>
        ) : (
          <DatesRangeInput
            selectsRange={dropOffLocationType !== "oneWay"}
            setReturnDate={setEndDate}
            setDepartureDate={setStartDate}
          />
        )}
      </div>
    );
  };

  const renderGenerals = () => {
    const isActive = fieldNameShow === "general";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("general")}
          >
            <span className="text-neutral-400">Flight type?</span>
            <span>{`${
              dropOffLocationType === "oneWay" ? "one-way" : "Return"
            }, ${flightClassState}`}</span>
          </button>
        ) : (
          <div className="p-5">
            <span className="block font-semibold text-xl sm:text-2xl">
              Flight type?
            </span>
            <div className="relative mt-5">
              <div className="flex space-x-2">
                <div
                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
                    dropOffLocationType === "return"
                      ? "bg-black shadow-black/10 shadow-lg text-white"
                      : "border border-neutral-300 dark:border-neutral-700"
                  }`}
                  onClick={(e) => setDropOffLocationType("return")}
                >
                  Return
                </div>
                <div
                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
                    dropOffLocationType === "oneWay"
                      ? "bg-black text-white shadow-black/10 shadow-lg"
                      : "border border-neutral-300 dark:border-neutral-700"
                  }`}
                  onClick={(e) => setDropOffLocationType("oneWay")}
                >
                  One-way
                </div>
              </div>

              <div className="mt-6">
                <label className="text-base font-semibold" htmlFor="">
                  Ticket Class:
                </label>
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                  {renderRadio("class", "Economy", "Economy")}
                  {renderRadio("class", "PremiumEconomy", "Premium Economy")}
                  {renderRadio("class", "Business", "Business")}
                  {renderRadio("class", "First", "First")}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderRadio = (
    name: string,
    id: string,
    label: string,
    defaultChecked?: boolean
  ) => {
    return (
      <div className="flex items-center ">
        <input
          defaultChecked={flightClassState === label}
          id={id + name}
          name={name}
          onChange={() => setFlightClassState(label)}
          type="radio"
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  };

  const renderInputGuests = () => {
    const isActive = fieldNameShow === "guests";
    let guestSelected = "";
    if (guestInput.adults || guestInput.children) {
      const guest = (guestInput.adults || 0) + (guestInput.children || 0);
      guestSelected += `${guest} guests`;
    }

    if (guestInput.infants) {
      guestSelected += `, ${guestInput.infants} infants`;
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("guests")}
          >
            <span className="text-neutral-400">Who</span>
            <span>{guestSelected || `Add guests`}</span>
          </button>
        ) : (
          <GuestsInput defaultValue={guestInput} onChange={setGuestInput} />
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="w-full space-y-5">
        {renderInputLocationPickup()}
        {/*  */}
        {renderInputLocationDropoff()}
        {/*  */}
        {renderGenerals()}
        {/*  */}
        {renderInputDates()}
        {/*  */}
        {renderInputGuests()}
      </div>
      <div
        className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between"
        style={{ position: "absolute", bottom: 0,width:"100%" }}
      >
        <Stack sx={{width:'100%'}} direction={"row"} justifyContent={"space-between"}> 
          <button
            type="button"
            className="underline font-semibold flex-shrink-0"
            // onClick={() => {
            //   setShowDialog(false);
            //   resetIsShowingDialog();
            // }}
          >
            Clear all
          </button>
          <ButtonSubmit
            onClick={() => {
              // navigate("/listing-flights")
              // setTrigger((trigger) => trigger + 1);
              // localStorage.setItem("realTrigger",JSON.stringify(trigger),)
              closeModal();
              handleFetchFlights();
            }}
          />
        </Stack>
      </div>
    </div>
  );
};

export default FlightSearchForm;
