import React, { FC } from "react";
import { useEffect, useState } from 'react';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero1.jpg";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { Button } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);
  useEffect(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []); 
const handleInstallClick = () => {
  if (deferredPrompt) {
    (deferredPrompt as any).prompt();
    (deferredPrompt as any).userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    });
  }
};

  
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
      style={{
        backgroundImage: `url(${imagePng})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "35vh", 
        justifyContent: "center",
      }}
    >
      <div className="flex flex-col justify-center items-center h-full text-center">
        <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[114%] text-white mb-8">
          Create Timeless Moments with Dr.Fly
        </h2>
        <Button variant="contained" style={{ display: deferredPrompt ? 'block' : 'none' }} onClick={handleInstallClick}> <FileDownloadIcon/> Install App</Button>
        <div className="hidden lg:flex">
          <HeroSearchForm />
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
