// import React, { FC } from "react";
// import Input from "shared/Input/Input";
// import Select from "shared/Select/Select";
// import CommonLayout from "./CommonLayout";
// import FormItem from "./FormItem";
// import { Stack } from "@mui/material";

// export interface PageAddListing1Props {}

// const PageAddListing1: FC<PageAddListing1Props> = () => {
//   return (
//     <CommonLayout
//       index="01"
//       backtHref="/add-listing-1"
//       nextHref="/add-listing-2"
//     >
//       <>
//         <h2 className="text-2xl font-semibold">Traveller information</h2>
//         <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

//         <Stack direction={"row"} spacing={3}>
//           <FormItem
//             label="Email address"
//             // desc="example@example.com"
//           >
//             <Input  />
//           </FormItem>
//           <FormItem
//             label="Mobile"
//             // desc="example@example.com"
//           >
//             <Input  />
//           </FormItem>
//         </Stack>
//         {/* FORM */}
//         <div className="space-y-8">{/* ITEM */}</div>
//       </>
//     </CommonLayout>
//   );
// };

// export default PageAddListing1;
import React, { useState, ChangeEvent, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Alert,
  Chip,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
} from "@mui/material";
import Input from "shared/Input/Input";
import FormItem from "./FormItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import handBag from "../../images/backpack.png";
import lauggage from "../../images/luggage.png";
import Suitcase from "../../images/suitcase.png";
import CollapsibleTable from "components/FlightCard/CollapsibleTable";
import Select from "shared/Select/Select";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import BackpackIcon from "@mui/icons-material/Backpack";
import LuggageIcon from "@mui/icons-material/Luggage";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import StripeApi from "../../apis/StripeApi";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useLocation } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";

const secretKeey =
  "sk_test_51O813oBIKEcS0riMtaIBA4oZZWrGKFZKXbciLY4UsgkbpsJGhvl0i8tAMemk8MRIi2Nb9G4Oo9sSShWSKpJxCGHy00ulU22ujM";
function TotalPrice(chosenFlight: any) {
  // Assuming chosenFlight contains travelerPricings array
  let totalPrice = 0;
  for (let i = 0; i < chosenFlight.travelerPricings.length; i++) {
    const numberValue = parseFloat(
      chosenFlight.travelerPricings[i].price.total
    );
    totalPrice += numberValue;
  }

  return (
    <div>
      <p>€{totalPrice.toFixed(2)}</p>
    </div>
  );
}
function StringToPrice(chosenFlight: any) {
  const numberValue = parseFloat(chosenFlight);

  return (
    <div>
      <p>€{numberValue.toFixed(2)}</p>
    </div>
  );
}

function formatTimestamp(timestamp: string): string {
  const date = new Date(timestamp);

  const daysOfWeek: string[] = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfWeek: string = daysOfWeek[date.getDay()];
  const month: string = monthsOfYear[date.getMonth()];
  const dayOfMonth: number = date.getDate();

  return `${dayOfWeek}, ${month} ${dayOfMonth}`;
}

function formatTime(timestamp: string): string {
  const date = new Date(timestamp);
  const hours = date.getHours().toString().padStart(2, "0"); // Add leading zero if necessary
  const minutes = date.getMinutes().toString().padStart(2, "0"); // Add leading zero if necessary
  const formattedTime = `${hours}:${minutes}`;
  return formattedTime;
}
function formatDuration(duration: string): string {
  const matches = duration.match(/PT(\d+)?H?(\d+)?M?/);
  if (!matches) return ""; // Return empty string if the duration doesn't match the expected format

  const hours = matches[1] ? parseInt(matches[1]) : 0;
  const minutes = matches[2] ? parseInt(matches[2]) : 0;

  const totalMinutes = hours * 60 + minutes;
  const formattedHours = Math.floor(totalMinutes / 60);
  const formattedMinutes = totalMinutes % 60;

  const hoursText =
    formattedHours > 0
      ? `${formattedHours} hour${formattedHours > 1 ? "s" : ""}`
      : "";
  const minutesText =
    formattedMinutes > 0
      ? `${formattedMinutes} minute${formattedMinutes > 1 ? "s" : ""}`
      : "";

  if (hoursText && minutesText) {
    return `${hoursText} ${minutesText}`;
  } else if (hoursText) {
    return hoursText;
  } else if (minutesText) {
    return minutesText;
  } else {
    return "0 minutes";
  }
}

const steps = ["Contact information", "Passengers ", "trip Configuration"];

export default function PageAddListing1() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleMobileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMobile(event.target.value);
  };

  const isStepOptional = (step: number) => {
    return step === 5;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    navigate("/checkout");
  };

  const storedBoxData = localStorage.getItem("boxData");
  const parsedBoxData = storedBoxData ? JSON.parse(storedBoxData) : null;
  const storedLocalData = localStorage.getItem("chosenflight");
  const parsedData = storedLocalData ? JSON.parse(storedLocalData) : null;
  const chosenFlight = parsedData?.flight;
  const outband = chosenFlight.itineraries[0];
  const inbound = chosenFlight.itineraries[1];
  const [loading, setLoading] = React.useState(false);

  const renderSidebar = () => {
    return (
      <div
        className="w-30 flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8 hidden lg:block"
        style={{ height: "100%", position: "sticky", top: 100 }}
      >
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          Your order
        </Typography>
        <div className="flex flex-col sm:flex-row sm:items-center">
          {/* <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src="	https://www.gstatic.com/flights/airline_logos/70px/MU.png"
              />
            </div>
          </div> */}

          <div className="py-5 sm:px-5 space-y-3">
            <span style={{ fontWeight: "bold" }}>outband</span>
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {parsedBoxData.fromValue.address.cityCode}{" "}
                {parsedBoxData.fromValue.address.cityName} -{" "}
                {parsedBoxData.toValue.address.cityCode}{" "}
                {parsedBoxData.toValue.address.cityName}
              </span>
              <span className="text-base font-medium mt-1 block">
                {formatTime(outband.segments[0].departure.at)} -{" "}
                {formatTime(
                  outband.segments[outband.segments.length - 1].arrival.at
                )}
                ( {formatDuration(outband.duration)})
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {formatTimestamp(outband.segments[0].departure.at)}{" "}
              <FlightLandIcon />
              {outband.segments.length >= 2
                ? `${
                    outband.segments.length - 1 === 1
                      ? outband.segments.length - 1 + " stop"
                      : outband.segments.length - 1 + " stops"
                  } `
                : "direct"}
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
          </div>

          {inbound && (
            <div className="py-5 sm:px-5 space-y-3">
              <span style={{ fontWeight: "bold" }}>Inbound</span>
              <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {parsedBoxData.toValue.address.cityCode}{" "}
                  {parsedBoxData.toValue.address.cityName} -{" "}
                  {parsedBoxData.fromValue.address.cityCode}{" "}
                  {parsedBoxData.fromValue.address.cityName}
                </span>
                <span className="text-base font-medium mt-1 block">
                  {formatTime(inbound.segments[0].departure.at)} -{" "}
                  {formatTime(
                    inbound.segments[inbound.segments.length - 1].arrival.at
                  )}
                  ( {formatDuration(inbound.duration)})
                </span>
              </div>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {formatTimestamp(inbound.segments[0].departure.at)}{" "}
                <FlightLandIcon />
                {inbound.segments.length >= 2
                  ? `${
                      inbound.segments.length - 1 === 1
                        ? inbound.segments.length - 1 + " stop"
                        : inbound.segments.length - 1 + " stops"
                    } `
                  : "direct"}
              </span>
              <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            </div>
          )}
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Travel details</h3>

          <Typography component="body" style={{ fontWeight: "bold" }}>
            Bags
          </Typography>
          <Typography variant="body2" style={{ fontWeight: "bold" }} my={1}>
            <BackpackIcon style={{ color: "#1976D2" }} /> Personal Item
          </Typography>
          {chosenFlight.travelerPricings.map((traveler: any, indx: number) => {
            return (
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="body2">Passenger {indx + 1}</Typography>
                <Typography variant="body2">
                  {traveler.travelerType === "HELD_INFANT"
                    ? "Contact the airline"
                    : "1x personal item included"}
                </Typography>
              </Stack>
            );
          })}
          <Typography variant="body2" style={{ fontWeight: "bold" }} my={1}>
            <LuggageIcon style={{ color: "#1976D2" }} /> Checked baggage
          </Typography>
          <Typography variant="body2">No baggage selected</Typography>

          <Divider sx={{ margin: "18px 0" }} />

          {chosenFlight.travelerPricings.map((traveler: any, indx: number) => {
            return (
              <Stack my={1}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Passenger {indx + 1}, {traveler.travelerType}
                  </Typography>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    {StringToPrice(traveler.price.total)}
                  </Typography>
                </Stack>
              </Stack>
            );
          })}

          <Divider sx={{ margin: "18px 0" }} />

          <div className="flex justify-between font-semibold">
            <span>Amount to be paid</span>
            <span> {TotalPrice(chosenFlight)}</span>
          </div>
        </div>
      </div>
    );
  };

  const makeAPayment = async () => {
    setLoading(true);
    const stripe = await loadStripe(
      "pk_test_51O813oBIKEcS0riMB689I2oyu3f5ldD3KwhXAEtOZNibMPf5z9O0ojMTtT6G4iBJqjwlgOLAqVaU3fS9xDp81Gsk00pTBaRldq"
    );
    console.log(chosenFlight);
    const body = chosenFlight;

    const response = await StripeApi.createSession(body);
    if (response.success) {
      stripe?.redirectToCheckout({
        sessionId: response.data?.id,
      });
      setLoading(false);
    } else {
      console.log(response.error);
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xl">
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={4}
        my={8}
        sx={{ minHeight: "100vh" }}
      >
        <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label} </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                {/* <ButtonPrimary onClick={()=>makeAPayment()}>Confirm and pay</ButtonPrimary> */}
                <LoadingButton
                  onClick={() => makeAPayment()}

          loading={loading}
                  variant="outlined"
                >
                  Confirm and pay
                </LoadingButton>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 ? (
                <Paper
                  elevation={1}
                  style={{ margin: "35px 0", padding: "20px" }}
                >
                  <Stack>
                    <Typography variant="h5" gutterBottom>
                      Contact information for all passengers{" "}
                      <Tooltip
                        title="Enter the email address where your confirmation will be sent.Make sure it is correct."
                        placement="top"
                        arrow
                      >
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Stack direction={"row"} spacing={2} py={4}>
                      <FormItem
                        label="Email address*"
                        // desc="example@example.com"
                      >
                        <Input value={email} onChange={handleEmailChange} />
                      </FormItem>
                      <FormItem label="Mobile*" desc="Enter your number">
                        <Input
                          type="tel"
                          value={mobile}
                          onChange={handleMobileChange}
                        />
                      </FormItem>
                    </Stack>
                  </Stack>
                </Paper>
              ) : activeStep === 1 ? (
                chosenFlight?.travelerPricings.map(
                  (traveler: any, indx: number) => {
                    return traveler?.travelerType === "ADULT" ? (
                      <Paper
                        elevation={1}
                        style={{ margin: "35px 0", padding: "20px" }}
                      >
                        <Stack spacing={2}>
                          <Typography
                            variant="h5"
                            style={{ fontWeight: "bold" }}
                          >
                            Passenger {indx + 1}, Adult
                          </Typography>
                          <Typography variant="caption" gutterBottom>
                            *Field required
                          </Typography>

                          <Alert severity="info">
                            Please enter your first and last name exactly as
                            they appear on your passport/ID to avoid problems at
                            the airport. If there is a middle name, enter it in
                            the name field.
                          </Alert>
                          <Grid container my={2}>
                            <Grid item>
                              <FormControl>
                                <Typography component="body">
                                  Salutation*
                                </Typography>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                >
                                  <FormControlLabel
                                    value="male"
                                    control={<Radio />}
                                    label="Mr."
                                  />
                                  <FormControlLabel
                                    value="female"
                                    control={<Radio />}
                                    label="Mrs."
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid container spacing={0.5} my={2}>
                              <Grid item md={6} width={"100%"}>
                                <FormItem
                                  label="First name*"
                                  // desc="example@example.com"
                                >
                                  <Input placeholder="First name and middle name" />
                                </FormItem>
                              </Grid>
                              <Grid item md={6} width={"100%"}>
                                <FormItem label="Surname*">
                                  <Input placeholder="Surname" />
                                </FormItem>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} my={2}>
                              <FormItem
                                label="Date of Birth*"
                                desc="Enter your date of birth as follows: Year-month-day (YYYY-MM-DD)"
                              >
                                <Input placeholder="YYYY-MM-DD" />
                              </FormItem>
                            </Grid>
                            {/* <Grid container spacing={1} my={2}>
                              <Grid item xs={12} md={4}>
                                <FormItem label="Document type*">
                                  <Select>
                                    <option value="Viet Nam">Passport</option>
                                    <option value="Thailand">
                                      Travel document
                                    </option>
                                    <option value="France">Id</option>
                                    <option value="Singapore">
                                      Driving license
                                    </option>
                                    <option value="Jappan">
                                      deplomatic passport
                                    </option>
                                  </Select>
                                </FormItem>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormItem label="Document number*">
                                  <Input />
                                </FormItem>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormItem label="Nationality*">
                                  <Input />
                                </FormItem>
                              </Grid>
                            </Grid> */}
                          </Grid>

                          <Stack>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: "bold" }}
                            >
                              Select baggage
                            </Typography>
                            <Stack
                              style={{ border: "1px solid rgb(229, 231, 235)" }}
                              p={2}
                              mb={3}
                            >
                              <Typography
                                variant="body1"
                                style={{ fontWeight: "bold" }}
                              >
                                Personal Item
                              </Typography>
                              <Typography variant="body2">
                                A small bag that should fit under the seat in
                                front of you.
                              </Typography>
                              <Stack
                                direction={"row"}
                                py={2}
                                alignItems={"center"}
                                spacing={4}
                              >
                                <img src={handBag} width={75} alt="" />
                                <Chip
                                  label="Including"
                                  color="success"
                                  size="medium"
                                />
                              </Stack>
                            </Stack>
                          </Stack>

                          <Stack>
                            <Stack
                              style={{ border: "1px solid rgb(229, 231, 235)" }}
                              p={2}
                              mb={3}
                            >
                              <Typography
                                variant="body1"
                                style={{ fontWeight: "bold" }}
                              >
                                Carry-on baggage
                              </Typography>
                              <Typography variant="body2">
                                Backpack or handbag that goes in the main
                                compartment.
                              </Typography>
                              <Stack
                                direction={"row"}
                                py={2}
                                alignItems={"center"}
                                spacing={3}
                              >
                                <img src={lauggage} width={75} alt="" />
                                <Chip
                                  label="Including"
                                  color="success"
                                  size="medium"
                                />
                                <Typography variant="body2">1 x 8kg</Typography>
                              </Stack>
                            </Stack>
                          </Stack>

                          <Stack>
                            <Stack
                              style={{ border: "1px solid rgb(229, 231, 235)" }}
                              p={2}
                            >
                              <Typography
                                variant="body1"
                                style={{ fontWeight: "bold" }}
                              >
                                Checked baggage
                              </Typography>
                              <Typography variant="body2">
                                Suitcase or other bag you want to check in.
                              </Typography>
                              <Stack
                                direction={"row"}
                                py={2}
                                alignItems={"center"}
                                spacing={3}
                              >
                                <img src={Suitcase} width={75} alt="" />
                                <RadioGroup
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                >
                                  <FormControlLabel
                                    value="null"
                                    control={<Radio />}
                                    label="No checked baggage"
                                  />
                                  <FormControlLabel
                                    value="20"
                                    control={<Radio />}
                                    label="1 x 20 kg"
                                  />
                                  <Typography
                                    style={{
                                      fontWeight: "bold",
                                      color: "#2563eb",
                                    }}
                                  >
                                    Add baggage now instead of at the airport
                                    and save up to 50%!
                                  </Typography>
                                </RadioGroup>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Paper>
                    ) : traveler?.travelerType === "CHILD" ? (
                      <Paper
                        elevation={1}
                        style={{ margin: "35px 0", padding: "20px" }}
                      >
                        <Stack spacing={2}>
                          <Typography
                            variant="h5"
                            style={{ fontWeight: "bold" }}
                          >
                            Passenger {indx + 1}, Child
                          </Typography>
                          <Typography variant="caption" gutterBottom>
                            *Field required
                          </Typography>

                          <Alert severity="info">
                            Please enter your first and last name exactly as
                            they appear on your passport/ID to avoid problems at
                            the airport. If there is a middle name, enter it in
                            the name field.
                          </Alert>

                          <FormControl>
                            <Typography component="body">
                              Salutation*
                            </Typography>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              <FormControlLabel
                                value="male"
                                control={<Radio />}
                                label="Boy"
                              />
                              <FormControlLabel
                                value="female"
                                control={<Radio />}
                                label="Girl"
                              />
                            </RadioGroup>
                          </FormControl>
                          <Stack direction={"row"} spacing={3}>
                            <FormItem
                              label="First name*"
                              // desc="example@example.com"
                            >
                              <Input placeholder="First name and middle name" />
                            </FormItem>
                            <FormItem label="Surname*">
                              <Input placeholder="Surname" />
                            </FormItem>
                          </Stack>

                          <FormItem
                            label="Date of Birth*"
                            desc="Enter your date of birth as follows: Year-month-day (YYYY-MM-DD)"
                          >
                            <Input placeholder="YYYY-MM-DD" />
                          </FormItem>

                          <Stack>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: "bold" }}
                            >
                              Select baggage
                            </Typography>
                            <Stack
                              style={{ border: "1px solid rgb(229, 231, 235)" }}
                              p={2}
                              mb={3}
                            >
                              <Typography
                                variant="body1"
                                style={{ fontWeight: "bold" }}
                              >
                                Personal Item
                              </Typography>
                              <Typography variant="body2">
                                A small bag that should fit under the seat in
                                front of you.
                              </Typography>
                              <Stack
                                direction={"row"}
                                py={2}
                                alignItems={"center"}
                                spacing={4}
                              >
                                <img src={handBag} width={75} alt="" />
                                <Chip
                                  label="Including"
                                  color="success"
                                  size="medium"
                                />
                              </Stack>
                            </Stack>
                          </Stack>

                          <Stack>
                            <Stack
                              style={{ border: "1px solid rgb(229, 231, 235)" }}
                              p={2}
                              mb={3}
                            >
                              <Typography
                                variant="body1"
                                style={{ fontWeight: "bold" }}
                              >
                                Carry-on baggage
                              </Typography>
                              <Typography variant="body2">
                                Backpack or handbag that goes in the main
                                compartment.
                              </Typography>
                              <Stack
                                direction={"row"}
                                py={2}
                                alignItems={"center"}
                                spacing={3}
                              >
                                <img src={lauggage} width={75} alt="" />
                                <Chip
                                  label="Including"
                                  color="success"
                                  size="medium"
                                />
                                <Typography variant="body2">1 x 8kg</Typography>
                              </Stack>
                            </Stack>
                          </Stack>

                          <Stack>
                            <Stack
                              style={{ border: "1px solid rgb(229, 231, 235)" }}
                              p={2}
                            >
                              <Typography
                                variant="body1"
                                style={{ fontWeight: "bold" }}
                              >
                                Checked baggage
                              </Typography>
                              <Typography variant="body2">
                                Suitcase or other bag you want to check in.
                              </Typography>
                              <Stack
                                direction={"row"}
                                py={2}
                                alignItems={"center"}
                                spacing={3}
                              >
                                <img src={Suitcase} width={75} alt="" />
                                <RadioGroup
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                >
                                  <FormControlLabel
                                    value="null"
                                    control={<Radio />}
                                    label="No checked baggage"
                                  />
                                  <FormControlLabel
                                    value="20"
                                    control={<Radio />}
                                    label="1 x 20 kg"
                                  />
                                  <Typography
                                    style={{
                                      fontWeight: "bold",
                                      color: "#4F46E5",
                                    }}
                                  >
                                    Add baggage now instead of at the airport
                                    and save up to 50%!
                                  </Typography>
                                </RadioGroup>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Paper>
                    ) : (
                      <Paper
                        elevation={3}
                        style={{ margin: "35px 0", padding: "20px" }}
                      >
                        <Stack spacing={2}>
                          <Typography
                            variant="h5"
                            style={{ fontWeight: "bold" }}
                          >
                            Passenger {indx + 1}, Child
                          </Typography>
                          <Typography variant="caption" gutterBottom>
                            *Field required
                          </Typography>

                          <Alert severity="info">
                            Please enter your first and last name exactly as
                            they appear on your passport/ID to avoid problems at
                            the airport. If there is a middle name, enter it in
                            the name field.
                          </Alert>

                          {/* <FormControl>
                            <Typography component="body">
                              Salutation*
                            </Typography>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              <FormControlLabel
                                value="male"
                                control={<Radio />}
                                label="Boy"
                              />
                              <FormControlLabel
                                value="female"
                                control={<Radio />}
                                label="Girl"
                              />
                            </RadioGroup>
                          </FormControl> */}
                          <Stack direction={"row"} spacing={3}>
                            <FormItem
                              label="First name*"
                              // desc="example@example.com"
                            >
                              <Input placeholder="First name and middle name" />
                            </FormItem>
                            <FormItem label="Surname*">
                              <Input placeholder="Surname" />
                            </FormItem>
                          </Stack>

                          <FormItem
                            label="Date of Birth*"
                            desc="Enter your date of birth as follows: Year-month-day (YYYY-MM-DD)"
                          >
                            <Input placeholder="YYY-MM-DD" />
                          </FormItem>

                          <Stack>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: "bold" }}
                            >
                              Select baggage
                            </Typography>
                            <Typography component="body">
                              It is not possible to add luggage for infants.
                            </Typography>
                          </Stack>
                        </Stack>
                      </Paper>
                    );
                  }
                )
              ) : (
                <Paper
                  elevation={1}
                  style={{ margin: "35px 0", padding: "20px" }}
                >
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    Choose your own bundle
                  </Typography>
                  <Typography component="body" mb={5}>
                    Save 20% with the offer, compared to one product purchase.
                  </Typography>
                  <CollapsibleTable />
                </Paper>
              )}

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleNext} disabled={!email || !mobile}>
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </div>
        {renderSidebar()}
      </Stack>
    </Container>
  );
}
