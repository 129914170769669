import { Box, Button, Stack, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

// export interface FlightCardProps {
//   className?: string;
//   defaultOpen?: boolean;
//   data: {
//     id: string;
//     airlines: {
//       logo: string;
//       name: string;
//     };
//     price: string;
//   };
// }
function formatTimestamp(timestamp: string): string {
  const date = new Date(timestamp);

  const daysOfWeek: string[] = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfWeek: string = daysOfWeek[date.getDay()];
  const month: string = monthsOfYear[date.getMonth()];
  const dayOfMonth: number = date.getDate();
  const hours: number = date.getHours();
  const minutes: number = date.getMinutes();

  return `${dayOfWeek}, ${month} ${dayOfMonth} · ${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}`;
}

function formatDuration(duration: string) {
  // Check if the duration starts with "PT"
  if (duration.startsWith("PT")) {
    // Extract hours and minutes using regular expressions
    const hoursRegex = /PT(\d+)H/;
    const minutesRegex = /H(\d+)M/;
    const hoursMatch = duration.match(hoursRegex);
    const minutesMatch = duration.match(minutesRegex);

    // Initialize hours and minutes
    let formattedHours = 0;
    let formattedMinutes = 0;

    // If hours are provided, extract and convert to integer
    if (hoursMatch) {
      formattedHours = parseInt(hoursMatch[1]);
    }

    // If minutes are provided, extract and convert to integer
    if (minutesMatch) {
      formattedMinutes = parseInt(minutesMatch[1]);
    }

    const hoursText =
      formattedHours > 0
        ? `${formattedHours} hour${formattedHours > 1 ? "s" : ""}`
        : "";
    const minutesText =
      formattedMinutes > 0
        ? `${formattedMinutes} minute${formattedMinutes > 1 ? "s" : ""}`
        : "";

    if (hoursText && minutesText) {
      return `${hoursText} ${minutesText}`;
    } else if (hoursText) {
      return hoursText;
    } else if (minutesText) {
      return minutesText;
    }
  }

  // Return "test" if the input format is incorrect
  return "test";
}

function calculateTransitTime(segment1: any, segment2: any): string {
  const arrivalTimeSegment1 = new Date(segment1?.arrival.at);
  const departureTimeSegment2 = new Date(segment2?.departure.at);

  const transitTime =
    departureTimeSegment2.getTime() - arrivalTimeSegment1.getTime();

  // Convert milliseconds to hours and minutes
  const hours = Math.floor(transitTime / (1000 * 60 * 60));
  const minutes = Math.floor((transitTime % (1000 * 60 * 60)) / (1000 * 60));

  // Format the transit time
  //const formattedTransitTime = `${hours} hours ${minutes} minutes`;
  if (hours && minutes) {
    const formattedTransitTime = `${hours} hours ${minutes} minutes`;
    return formattedTransitTime;
  }
  if (hours) {
    return `${hours} hours`;
  }
  if (minutes) {
    return `${minutes} minutes`;
  }
  return` test`;
}
function formatTime(timestamp: string): string {
  const date = new Date(timestamp);
  const hours = date.getHours().toString().padStart(2, "0"); // Add leading zero if necessary
  const minutes = date.getMinutes().toString().padStart(2, "0"); // Add leading zero if necessary
  const formattedTime = `${hours}:${minutes}`;
  return formattedTime;
}

export interface FlightCardProps {
  className?: string;
  defaultOpen?: boolean;
  data: {
    id: string;
    numberOfBookableSeats: number;
    price: {
      currency: string;
      total: string;
    };
    itineraries: [
      {
        duration: string;
        segments: [
          {
            aircraft: { code: string };
            carrierCode: string;
            number: any;
            duration: string;
            departure: {
              at: string;
              iataCode: string;
              terminal: string | null;
            };
            arrival: {
              at: string;
              iataCode: string;
              terminal: string | null;
            };
          },
          {
            aircraft: { code: string };
            carrierCode: string;
            number: any;
            duration: string;
            departure: {
              at: string;
              iataCode: string;
              terminal: string | null;
            };
            arrival: {
              at: string;
              iataCode: string;
              terminal: string | null;
            };
          }
        ];
      },
      {
        duration: string;
        segments: [
          {
            aircraft: { code: string };
            carrierCode: string;
            duration: string;
            departure: {
              at: string;
              iataCode: string;
              terminal: string | null;
            };
            arrival: {
              at: string;
              iataCode: string;
              terminal: string | null;
            };
          },
          {
            aircraft: { code: string };
            carrierCode: string;
            duration: string;
            departure: {
              at: string;
              iataCode: string;
              terminal: string | null;
            };
            arrival: {
              at: string;
              iataCode: string;
              terminal: string | null;
            };
          }
        ];
      }
    ];
    airlines: {
      logo: string;
      name: string;
    };
    travelerPricings: [
      {
        fareDetailsBySegment: [
          {
            cabin: string;
            class: string;
            fareBasis: string;
            includedCheckedBags: {
              weight: number;
              weightUnit: string;
            };
            segmentId: number;
          }
        ];
        fareOption: string;
        travelerId: number;
        travelerType: string;
      }
    ];
  };
}

const FlightCard: FC<FlightCardProps> = ({
  className = "",
  data,
  defaultOpen = false,
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const outband = data.itineraries[0];
  const inbound = data.itineraries[1];

  const handleSelectFlight = (flight: any) => {
    localStorage.setItem(
      "chosenflight",
      JSON.stringify({
        flight,
      })
    );
    navigate("/traveler-details");
  };

  const renderDetailTop = (
    journy:
      | FlightCardProps["data"]["itineraries"][0]
      | FlightCardProps["data"]["itineraries"][1]
  ) => {
    return (
      <div>
        {journy?.segments?.map((segment, indx) => (
          <>
            <div className="flex flex-col md:flex-row ">
              <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
                <img
                  src={`https://www.gstatic.com/flights/airline_logos/70px/${journy.segments[indx].carrierCode}.png`}
                  className="w-10"
                  alt=""
                />
              </div>
              <div className="flex my-5 md:my-0">
                <div className="flex-shrink-0 flex flex-col items-center py-2">
                  <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                  <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
                  <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                </div>
                <div className="ml-4 space-y-10 text-sm">
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {/* Monday, August 12 · 10:00 */}
                      {formatTimestamp(segment.departure.at)}
                    </span>
                    <span className=" font-semibold">
                      {/* Tokyo International Airport (HND) */}
                      {segment.departure.iataCode}
                    </span>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {/* Monday, August 16 · 10:00 */}
                      {formatTimestamp(segment.arrival.at)}
                    </span>
                    <span className=" font-semibold">
                      {/* Singapore International Airport (SIN) */}
                      {segment.arrival.iataCode}
                    </span>
                  </div>
                </div>
              </div>
              <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
              <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
                {/* <li>Trip time: 7 hours 45 minutes</li> */}
                <li>Trip time: {formatDuration(segment.duration)}</li>
                <li>
                  {segment.carrierCode} ·{" "}
                  {
                    data.travelerPricings[0].fareDetailsBySegment[
                      data.travelerPricings[0].fareDetailsBySegment.length - 1
                    ].cabin
                  }{" "}
                  class · {segment.aircraft.code}{" "}
                </li>
              </ul>
            </div>
            {journy.segments.length > 1 &&
              indx !== journy.segments.length - 1 && (
                <div className="my-7 md:my-10 space-y-5 md:pl-24">
                  <div className="border-t border-neutral-200 dark:border-neutral-700" />
                  <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base">
                    Transit time:{" "}
                    {calculateTransitTime(
                      journy.segments[0],
                      journy.segments[1]
                    )}{" "}
                    - {journy.segments[0].arrival.iataCode} 
                  </div>
                  <div className="border-t border-neutral-200 dark:border-neutral-700" />
                </div>
              )}
          </>
        ))}
      </div>
    );
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
        {inbound && <span style={{ color: "#2563EB" }}>outband</span>}
        {renderDetailTop(outband)}
        {/* <div className="my-7 md:my-10 space-y-5 md:pl-24">
          <div className="border-t border-neutral-200 dark:border-neutral-700" />
          <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base">
            Transit time: 15 hours 45 minutes - Bangkok (BKK)
          </div>
          <div className="border-t border-neutral-200 dark:border-neutral-700" />
        </div> */}

        {inbound && (
          <div className="my-10 py-10 border-t border-neutral-200 dark:border-neutral-700">
            <span style={{ color: "#2563EB" }}>inbound</span>
            {renderDetailTop(inbound)}
          </div>
        )}
        <div className="flex justify-end mt-1">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            onClick={() => handleSelectFlight(data)}
          >
            Select
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FlightCard"
    >
      <div
        className={` sm:pr-20 relative  ${className}`}
        data-nc-id="FlightCard"
        onClick={() => setIsOpen(!isOpen)}
      >
        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a href="##" className="absolute inset-0" />

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen ? "transform -rotate-180" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>

        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box width={"100%"}>
            <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0 my-5">
              {/* LOGO IMG */}
              <div className="w-24 lg:w-32 flex-shrink-0">
                <img
                  src={`https://www.gstatic.com/flights/airline_logos/70px/${outband?.segments[0].carrierCode}.png`}
                  className="w-10"
                  alt=""
                />
              </div>

              {/* FOR MOBILE RESPONSIVE */}
              <div className="block lg:hidden space-y-1">
                <div className="flex font-semibold">
                  <div>
                    <span>{formatTime(outband.segments[0].departure.at)}</span>
                    <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                      {outband.segments[0].departure.iataCode}
                    </span>
                  </div>
                  <span className="w-12 flex justify-center">
                    <i className=" text-2xl las la-long-arrow-alt-right"></i>
                  </span>
                  <div>
                    <span>{formatTime(outband.segments[0].arrival.at)}</span>
                    <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                      {outband.segments[0].arrival.iataCode}
                    </span>
                  </div>
                </div>

                <div className="text-sm text-neutral-500 font-normal mt-0.5">
                  <span className="VG3hNb">
                    {outband.segments.length >= 2
                      ? `${
                          outband.segments.length - 1 === 1
                            ? outband.segments.length - 1 + " stop"
                            : outband.segments.length - 1 + " stops"
                        } `
                      : "direct"}
                  </span>
                  <span className="mx-2">·</span>
                  <span>
                    {calculateTransitTime(
                      data.itineraries[0].segments[0],
                      data.itineraries[0].segments[1]
                    )}{'-'}
                  </span>
                  <span className="mx-2">·</span>
                  <span>
                    {data.itineraries[0].segments[0].arrival.iataCode}{" "}
                  </span>
                </div>
              </div>

              {/* TIME - NAME */}
              <div className="hidden lg:block  min-w-[150px] flex-[4] ">
                <div className="font-medium text-lg">
                  {formatTime(outband.segments[0].departure.at)} -{" "}
                  {formatTime(
                    outband.segments[outband.segments.length - 1].arrival.at
                  )}
                </div>
                <div className="text-sm text-neutral-500 font-normal mt-0.5">
                  {outband.segments[0].carrierCode}
                </div>
              </div>

              {/* TIMME */}
              <div className="hidden lg:block flex-[4] whitespace-nowrap">
                <div className="font-medium text-lg">
                  {" "}
                  {outband.segments[0].departure.iataCode} -{" "}
                  {outband.segments[0].arrival.iataCode}{" "}
                </div>
                <div className="text-sm text-neutral-500 font-normal mt-0.5">
                  {formatDuration(outband.duration)}
                </div>
              </div>

              {/* TYPE */}
              <div className="hidden lg:block flex-[4] whitespace-nowrap">
                <div className="font-medium text-lg">
                  {" "}
                  {outband.segments.length >= 2
                    ? `${
                        outband.segments.length - 1 === 1
                          ? outband.segments.length - 1 + " stop"
                          : outband.segments.length - 1 + " stops"
                      } `
                    : "direct"}
                </div>
                {data.itineraries[0].segments.length > 1 && (
                  <div className="text-sm text-neutral-500 font-normal mt-0.5">
                    {calculateTransitTime(
                      data.itineraries[0].segments[0],
                      data.itineraries[0].segments[1]
                    )}{' - '}
                    {data.itineraries[0].segments[0].arrival.iataCode}
                  </div>
                )}
              </div>

              {/* PRICE */}
              {/* <div className="flex-[4] whitespace-nowrap sm:text-right">
            {!inbound && (
              <>
                <div className="lg:block  xs:hidden">
                  <span className="text-xl font-semibold text-secondary-6000 ">
                    {Math.round(parseFloat(data.price.total))}€
                  </span>
                </div>
                <div className="block  xs:hidden text-xs sm:text-sm text-neutral-500 font-normal mt-0.5 ">
                  {data.numberOfBookableSeats < 8
                    ? `${data.numberOfBookableSeats} seats left`
                    : ""}
                </div>
              </>
            )}
          </div> */}
              {/* PRICE */}

              <div className="flex-[4] whitespace-nowrap sm:text-right "></div>
            </div>

            {inbound && (
              <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                {/* LOGO IMG */}
                <div className="w-24 lg:w-32 flex-shrink-0">
                  <img
                    src={`https://www.gstatic.com/flights/airline_logos/70px/${inbound.segments[0].carrierCode}.png`}
                    className="w-10"
                    alt=""
                  />
                </div>

                {/* FOR MOBILE RESPONSIVE */}
                <div className="block lg:hidden space-y-1">
                  <div className="flex font-semibold">
                    <div>
                      <span>{inbound.segments[0].departure.at}</span>
                      <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                        {inbound.segments[0].departure.iataCode}
                      </span>
                    </div>
                    <span className="w-12 flex justify-center">
                      <i className=" text-2xl las la-long-arrow-alt-right"></i>
                    </span>
                    <div>
                      <span>{inbound.segments[0].arrival.at}</span>
                      <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                        {inbound.segments[0].arrival.iataCode}
                      </span>
                    </div>
                  </div>

                  <div className="text-sm text-neutral-500 font-normal mt-0.5">
                    <span className="VG3hNb">
                      {inbound.segments.length >= 2
                        ? `${
                            inbound.segments.length - 1 === 1
                              ? inbound.segments.length - 1 + " stop"
                              : inbound.segments.length - 1 + " stops"
                          } `
                        : "direct"}
                    </span>
                    <span className="mx-2">·</span>
                    <span>
                      {calculateTransitTime(
                        data.itineraries[1].segments[0],
                        data.itineraries[1].segments[1]
                      )}{'-'}
                    </span>
                    <span className="mx-2">·</span>
                    <span>
                      {data.itineraries[1].segments[0].arrival.iataCode}{" "}
                    </span>
                  </div>
                </div>

                {/* TIME - NAME */}
                <div className="hidden lg:block  min-w-[150px] flex-[4] ">
                  <div className="font-medium text-lg">
                    {formatTime(inbound.segments[0].departure.at)} -{" "}
                    {formatTime(
                      inbound.segments[inbound.segments.length - 1].arrival.at
                    )}
                  </div>
                  <div className="text-sm text-neutral-500 font-normal mt-0.5">
                    {inbound.segments[0].carrierCode}
                  </div>
                </div>

                {/* TIMME */}
                <div className="hidden lg:block flex-[4] whitespace-nowrap">
                  <div className="font-medium text-lg">
                    {" "}
                    {inbound.segments[0].departure.iataCode} -{" "}
                    {inbound.segments[0].arrival.iataCode}{" "}
                  </div>
                  <div className="text-sm text-neutral-500 font-normal mt-0.5">
                    {formatDuration(inbound.duration)}
                  </div>
                </div>

                {/* TYPE */}
                <div className="hidden lg:block flex-[4] whitespace-nowrap">
                  <div className="font-medium text-lg">
                    {" "}
                    {inbound.segments.length >= 2
                      ? `${
                          inbound.segments.length - 1 === 1
                            ? inbound.segments.length - 1 + " stop"
                            : inbound.segments.length - 1 + " stops"
                        } `
                      : "direct"}
                  </div>
                  {data.itineraries[1].segments.length > 1 && (
                    <div className="text-sm text-neutral-500 font-normal mt-0.5">
                      {calculateTransitTime(
                        data.itineraries[1].segments[0],
                        data.itineraries[1].segments[1]
                      )}{" "}
                      { data.itineraries[1].segments[0].arrival.iataCode}
                    </div>
                  )}
                </div>

                {/* PRICE */}
                <div className="flex-[4] whitespace-nowrap sm:text-right ">
                  {/* <div>
                <span className="text-xl font-semibold text-secondary-6000 block  ">
                  {Math.round(parseFloat(data.price.total))}€
                </span>
              </div>
              <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
                {data.numberOfBookableSeats < 8
                  ? `${data.numberOfBookableSeats} seats left`
                  : ""}
              </div> */}
                </div>
              </div>
            )}
                      <Stack display={{ xs: 'block', sm: 'none' }} spacing={0.5}>
            <div style={{ width: "90px" }}>
              <span className="text-xl font-semibold text-secondary-6000 block  ">
                {Math.round(parseFloat(data.price.total))}€
              </span>
            </div>
            <div className="text-xs sm:text-sm text-neutral-500 font-normal">
              {data.numberOfBookableSeats < 8
                ? `${data.numberOfBookableSeats} seats left`
                : ""}
            </div>
            {/* <Button variant="contained" onClick={()=>handleSelectFlight(data)}>Select</Button> */}
          </Stack>
          </Box>
          <Stack display={{ xs: 'none', sm: 'block' }} spacing={0.5}>
            <div style={{ width: "90px" }}>
              <span className="text-xl font-semibold text-secondary-6000 block  ">
                {Math.round(parseFloat(data.price.total))}€
              </span>
            </div>
            <div className="text-xs sm:text-sm text-neutral-500 font-normal">
              {data.numberOfBookableSeats < 8
                ? `${data.numberOfBookableSeats} seats left`
                : ""}
            </div>
            {/* <Button variant="contained" onClick={()=>handleSelectFlight(data)}>Select</Button> */}
          </Stack>
        </Stack>
      </div>

      {/* DETAIL */}
      {renderDetail()}
    </div>
  );
};

export default FlightCard;
