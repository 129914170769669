import React, { InputHTMLAttributes, ChangeEvent } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
  value?: string; // New prop for the input value
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void; // New prop for handling input changes
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      sizeClass = "h-11 px-4 py-3",
      fontClass = "text-sm font-normal",
      rounded = "rounded-2xl",
      value,
      onChange,
      type = "text",
      ...args
    },
    ref
  ) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      // Check if the input is for mobile and allow only numbers
      if (type === "tel") {
        const onlyNums = event.target.value.replace(/[^0-9]/g, "");
        onChange?.({ ...event, target: { ...event.target, value: onlyNums } });
      } else {
        onChange?.(event);
      }
    };

    return (
      <input
        ref={ref}
        type={type}
        value={value} // Set the value of the input
        onChange={handleChange} // Handle input changes with number restriction
        className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${rounded} ${fontClass} ${sizeClass} ${className}`}
        {...args}
      />
    );
  }
);

export default Input;
