import React, { FC, useEffect, useState } from "react";
import LocationInput from "../LocationInput";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import FlightDateRangeInput from "./FlightDateRangeInput";
import { GuestsObject } from "../type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { addDays } from "date-fns";
import ButtonSubmit from "../ButtonSubmit";
import { useNavigate } from "react-router-dom";

function formatDate(dateString: Date | null) {
  if(dateString){
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
}

export interface FlightSearchFormProps {}

const flightClass = [
  {
    name: "ECONOMY",
    title: "Economy",
    href: "##",
  },
  {
    name: "PREMIUM_ECONOMY",
    title: "Premium Economy",
    href: "##",
  },
  {
    name: "BUSINESS",
    title: "Business",
    href: "##",
  },
  {
    name: "FIRST",
    title: "First ",
    href: "##",
  },
];
export interface Airport {
  id: string;
  name: string;
  iataCode: string;
  // Add other properties if needed
}
export interface FormData {
  OriginLocationCode: "";
  DestinationLocationCode: "";
  DepartureDate: "";
  ReturnDate: "";
  Adults: 1;
}

export type TypeDropOffLocationType = "return" | "oneWay" | "";

const FlightSearchForm: FC<FlightSearchFormProps> = () => {
  const navigate = useNavigate(); 

  const [dropOffLocationType, setDropOffLocationType] =
    useState<TypeDropOffLocationType>("return");
  const [flightClassState, setFlightClassState] = useState(flightClass[0]);

  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(1);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(0);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(0);
  const [fromValue, setFromValue] = useState<Airport | null>(null);
  const [toValue, setToValue] = useState<Airport | null>(null);
  const [departureDate, setDepartureDate] = useState<Date | null>(new Date());
  const [returnDate, setReturnDate] = useState<Date | null>(
    addDays(new Date(), 15)
  );
  const [searchBtn, setsearchBtn] = useState<boolean>(false);

  const [formData, setFormData] = useState({
    OriginLocationCode: "",
    DestinationLocationCode: "",
    DepartureDate: "",
    ReturnDate: "",
    Adults: 1,
  });

  const handleChangeForm = (name: string, value: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const storedLocalData = localStorage.getItem("boxData");
    const parsedData = storedLocalData ? JSON.parse(storedLocalData) : null;
    if (parsedData) {
      setDropOffLocationType(parsedData.dropOffLocationType || "return");
      setFlightClassState(parsedData.flightClassState || flightClass[0]); 
      setGuestAdultsInputValue(parsedData.guestAdultsInputValue || 1);
      setGuestChildrenInputValue(parsedData.guestChildrenInputValue || 0);
      setGuestInfantsInputValue(parsedData.guestInfantsInputValue || 0);
      setFromValue(parsedData.fromValue || null);
      setToValue(parsedData.toValue || null);
      setDepartureDate(
        parsedData.departureDate
          ? new Date(parsedData.departureDate)
          : new Date()
      );
      setReturnDate(
        parsedData.returnDate
          ? new Date(parsedData.returnDate)
          : addDays(new Date(), 15)
      );
    }
  }, []);

  useEffect(() => {
    if(flightClassState){
      handleChangeForm("TravelClass", flightClassState.name);
    }
    if(guestAdultsInputValue){
      handleChangeForm("Adults",guestAdultsInputValue)
    }
    if(guestChildrenInputValue){
      handleChangeForm("Children",guestChildrenInputValue)
    }
    if(guestInfantsInputValue){
      handleChangeForm("Infants",guestInfantsInputValue)
    }
    if (fromValue) {
      handleChangeForm("OriginLocationCode", fromValue.iataCode);
    } else {
      handleChangeForm("OriginLocationCode", "");
    }
    if(dropOffLocationType){
      if(dropOffLocationType === "return"){
        const formattedDate = formatDate(addDays(new Date(), 15));
        handleChangeForm("ReturnDate", formattedDate); 
      }
      
    }
    if (toValue) {
      handleChangeForm("DestinationLocationCode", toValue.iataCode);
    } else {
      handleChangeForm("DestinationLocationCode", "");
    }
    if (departureDate) {
      const formattedDate = formatDate(departureDate);
      handleChangeForm("DepartureDate", formattedDate);
    } else {
      handleChangeForm("DepartureDate", "");
    }
    if (returnDate) {
      if (dropOffLocationType === "oneWay") {
        handleChangeForm("ReturnDate", "");
        //setReturnDate(null);
      } else {
        const formattedDate = formatDate(returnDate);

        handleChangeForm("ReturnDate", formattedDate); 
      }
    } 

    if (fromValue && toValue && departureDate && (fromValue.iataCode !== toValue.iataCode)) {
      setsearchBtn(false);
    } else {
      setsearchBtn(true);
    }
  }, [
    fromValue,
    toValue,
    departureDate,
    returnDate,
    dropOffLocationType,
    searchBtn,
    flightClassState,
    guestAdultsInputValue,
    guestChildrenInputValue,
    guestInfantsInputValue
  ]);

  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      guestAdults: guestAdultsInputValue,
      guestChildren: guestChildrenInputValue,
      guestInfants: guestInfantsInputValue,
    };
    if (type === "adults") {
      setGuestAdultsInputValue(value);
      newValue.guestAdults = value;
    }
    if (type === "children") {
      setGuestChildrenInputValue(value);
      newValue.guestChildren = value;
    }
    if (type === "infants") {
      setGuestInfantsInputValue(value);
      newValue.guestInfants = value;
    }
  };
  const handleDropOffLocationType = (type: TypeDropOffLocationType) => {
    // setDepartureDate(null)
    // setReturnDate(null)
    setDropOffLocationType(type);
  };

  const handleFetchFlights = () => {
    localStorage.setItem("formData", JSON.stringify(formData));
    localStorage.setItem(
      "boxData",
      JSON.stringify({
        fromValue,
        toValue,
        departureDate,
        returnDate,
        dropOffLocationType,
        flightClassState,
        guestAdultsInputValue,
        guestChildrenInputValue,
        guestInfantsInputValue,
      })
    );
    const storedLocalData = localStorage.getItem("recentSearch");
    const parsedData = storedLocalData ? JSON.parse(storedLocalData) : [];
    const fromAlreadyExists = parsedData.some((item: any) => item.fromValue?.iataCode === fromValue?.iataCode);
    const toAlreadyExists = parsedData.some((item: any) => item.toValue?.iataCode === toValue?.iataCode);
    
    let newData: any = {};
    
    if (!fromAlreadyExists && !toAlreadyExists) {
        newData = {
            fromValue: fromValue,
            toValue: toValue
        };
        parsedData.push(newData);
    } else if (!toAlreadyExists) {
        newData = {
            toValue: toValue
        };
        parsedData.push(newData);
    } else if(!fromAlreadyExists) {
        newData = {
            fromValue: fromValue
        };
        parsedData.push(newData);
    }
    
    // Now you might want to save 'parsedData' back to localStorage
    localStorage.setItem("recentSearch", JSON.stringify(parsedData));
    //window.location.href = '/listing-flights';
    const currentUrl = window.location.pathname;
    const isListingFlightsPage = currentUrl.endsWith("/listing-flights");

    if (isListingFlightsPage) {
        // Refresh the page
        window.location.reload();
    } else {
        // Navigate to the listing-flights page
        navigate('/listing-flights');
    }
  };

  const totalGuests =
    guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;

  const renderGuest = () => {
    return (
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              as="button"
              className={`
           ${open ? "" : ""}
            px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              <span>{`${totalGuests || ""} Passengers`}</span>
              <ChevronDownIcon
                className={`${
                  open ? "" : "text-opacity-70"
                } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-20 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 left-1/2 -translate-x-1/2  py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl ring-1 ring-black/5 dark:ring-white/10">
                <NcInputNumber
                  className="w-full"
                  defaultValue={guestAdultsInputValue}
                  onChange={(value) => handleChangeData(value, "adults")}
                  max={10}
                  min={1}
                  label="Adults"
                  desc="Ages 13 or above"
                />
                <NcInputNumber
                  className="w-full mt-6"
                  defaultValue={guestChildrenInputValue}
                  onChange={(value) => handleChangeData(value, "children")}
                  max={4}
                  label="Children"
                  desc="Ages 2–12"
                />

                <NcInputNumber
                  className="w-full mt-6"
                  defaultValue={guestInfantsInputValue}
                  onChange={(value) => handleChangeData(value, "infants")}
                  max={4}
                  label="Infants"
                  desc="Ages 0–2"
                />
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderSelectClass = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
           ${open ? "" : ""}
            px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              <span>{`${flightClassState.title}`}</span>
              <ChevronDownIcon
                className={`${
                  open ? "" : "text-opacity-70"
                } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-20 w-screen max-w-[200px] sm:max-w-[220px] px-4 top-full mt-3 transform -translate-x-1/2 left-1/2 sm:px-0  ">
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10 ">
                  <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7 ">
                    {flightClass.map((item) => ( 
                      <a
                        key={item.title}
                        href={item.href}
                        onClick={(e) => {
                          e.preventDefault();
                          setFlightClassState(item); 
                          close();
                        }}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <p className="text-sm font-medium ">{item.title}</p>
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderRadioBtn = () => {
    return (
      <div className=" py-5 [ nc-hero-field-padding ] flex flex-row flex-wrap border-b border-neutral-100 dark:border-neutral-700">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 ${
            dropOffLocationType === "return"
              ? "bg-black shadow-black/10 shadow-lg text-white"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => handleDropOffLocationType("return")}
        >
          Return
        </div>
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 ${
            dropOffLocationType === "oneWay"
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => handleDropOffLocationType("oneWay")}
        >
          One-way
        </div>

        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8 mr-2 my-1 sm:mr-3"></div>

        <div className="mr-2 my-1 sm:mr-3 border border-neutral-300 dark:border-neutral-700 rounded-full">
          {renderSelectClass()}
        </div>
        <div className="my-1 border border-neutral-300 dark:border-neutral-700 rounded-full">
          {renderGuest()}
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 rounded-[40px] xl:rounded-[49px] rounded-t-2xl xl:rounded-t-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
        {renderRadioBtn()}
        <div className="flex flex-1 rounded-full">
          <LocationInput
            placeHolder="Flying from"
            desc="Where do you want to fly from?"
            setFrom={setFromValue}
            fromValue={fromValue}
            className="flex-1"
          />
          <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <LocationInput
            placeHolder="Flying to"
            desc="Where you want to fly to?"
            className="flex-1"
            setTo={setToValue}
            toValue={toValue}
            divHideVerticalLineClass=" -inset-x-0.5"
          />
          <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <FlightDateRangeInput
            selectsRange={dropOffLocationType !== "oneWay"}
            setReturnDate={setReturnDate}
            setDepartureDate={setDepartureDate}
            className="flex-1"
          />
          <div className="pr-2 xl:pr-4 pt-4">
            <ButtonSubmit
              href="/listing-flights"
              disabled={searchBtn}
              handleFetchFlights={handleFetchFlights}
            />
          </div>
        </div>
      </form>
    );
  };

  return renderForm();
};

export default FlightSearchForm;
