import StripeApi from "apis/StripeApi";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ModalReserveMobile from "./ListingDetailPage/(components)/ModalReserveMobile";

const ReturnPage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const session_id = searchParams.get("session_id");
  const navigate = useNavigate();
  // You can use session_id here to perform any necessary actions
  useEffect(()=>{
    StripeApi.getSession(session_id)
    .then((session:any) => {
      // Assuming you have a redirect URL in the session data

      const response = session.data;  
      console.log(response)
      if (response.paymentStatus ==="paid") {
        // Redirect to the specified URL
        navigate('/pay-done', { state: { data: response.amountTotal } });
      } else { 
        console.error('No redirect URL found in the session data.');
      }
    })
    .catch((error:any) => {
      console.error('Error getting session by ID:', error);
      // Optionally, handle the error or redirect to a default URL
    });
  },[])

  return (
    <div>
      
    </div>
  );
};

export default ReturnPage;
