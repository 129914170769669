import { GuestsObject } from "components/HeroSearchForm/type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { useEffect, useState } from "react";
import { FC } from "react";
export interface GuestsInputProps {
  defaultValue?: GuestsObject;
  onChange?: (data: GuestsObject) => void;
  className?: string;
}

const GuestsInput: FC<GuestsInputProps> = ({
  defaultValue,
  onChange,
  className = "",
}) => {
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(
    defaultValue?.adults || 0
  );
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(
    defaultValue?.children || 0
  );
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(
    defaultValue?.infants || 0
  );

  useEffect(() => {
    setGuestAdultsInputValue(defaultValue?.adults || 0);
  }, [defaultValue?.adults]);
  useEffect(() => {
    setGuestChildrenInputValue(defaultValue?.children || 0);
  }, [defaultValue?.children]);
  useEffect(() => {
    setGuestInfantsInputValue(defaultValue?.infants || 0);
  }, [defaultValue?.infants]);

  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      adults: guestAdultsInputValue,
      children: guestChildrenInputValue,
      infants: guestInfantsInputValue,
    };
    if (type === "adults") {
      setGuestAdultsInputValue(value);
      newValue.adults = value;
    }
    if (type === "children") {
      setGuestChildrenInputValue(value);
      newValue.children = value;
    }
    if (type === "infants") {
      setGuestInfantsInputValue(value);
      newValue.infants = value;
    }
    onChange && onChange(newValue);
  };

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <span className="mb-5 block font-semibold text-xl sm:text-2xl">
        {`Who's coming?`}
      </span>
      <NcInputNumber
        className="w-full"
        defaultValue={guestAdultsInputValue}
        onChange={(value) => handleChangeData(value, "adults")}
        max={20}
        label="Adults"
        desc="Ages 13 or above"
      />
      <NcInputNumber
        className="w-full mt-6"
        defaultValue={guestChildrenInputValue}
        onChange={(value) => handleChangeData(value, "children")}
        max={20}
        label="Children"
        desc="Ages 2–12"
      />

      <NcInputNumber
        className="w-full mt-6"
        defaultValue={guestInfantsInputValue}
        onChange={(value) => handleChangeData(value, "infants")}
        max={20}
        label="Infants"
        desc="Ages 0–2"
      />
    </div>
  );
};

export default GuestsInput;
