import axios from "axios"

const FLIGHTS_BASE_URL="https://app-drflyapi-dev.azurewebsites.net/api/Flights/"

const FlightsApi={

    getFlights: async(flight)=>{
        try{
            const queryParams = new URLSearchParams(flight).toString();
            const response = await axios.get(
                FLIGHTS_BASE_URL+ "GetFlights?"+queryParams
              );
              console.log(response)
              return { success: true, data:  response.data };
        } catch(error){
            return { success: false, error: error?.response?.data };
        }
    }

}
export default FlightsApi