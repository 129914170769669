import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import React, { FC, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import FlightSearchForm from "components/HeroSearchForm/(flight-search-form)/FlightSearchForm";

export interface ListingFlightsPageProps {
  className?: string;
}

const ListingFlightsPage: FC<ListingFlightsPageProps> = ({
  className = "",
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>Dr.Fly || Flight booking platform</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION HERO */}
        {/* <SectionHeroArchivePage
          currentPage="Flights"
          // currentTab="Flights"
          listingType={
            <>
              <i className="text-2xl las la-plane-departure"></i>
              <span className="ml-2.5">1599 flights</span>
            </>
          }
          className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
        /> */}
        {windowWidth > 1024 && <FlightSearchForm />}

        {/* SECTION */}
        <SectionGridFilterCard className="py-24 lg:pb-28" />

        {/* SECTION 1 */}
        {/* <SectionSliderNewCategories
          heading="Explore top destination ✈"
          subHeading="Explore thousands of destinations around the world"
          categoryCardType="card4"
          itemPerRow={4}
          categories={DEMO_CATS}
          uniqueClassName="ListingFlightsPage-section1"
        /> */}

        {/* SECTION */}
        {/* <SectionSubscribe2 className="py-24 lg:py-28" /> */}
      </div>
    </div>
  );
};

export default ListingFlightsPage;
