import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import convertNumbThousand from "utils/convertNumbThousand";
import Slider from "rc-slider";
import { Grid } from "@mui/material";

const airlines = [
  { name: "Valuair", carrierCode: "VF" },
  { name: "Aegean Airlines", carrierCode: "A3" },
  { name: "American Airlines", carrierCode: "AA" },
  { name: "Delta Air Lines", carrierCode: "DL" },
  { name: "United Airlines", carrierCode: "UA" },
  { name: "Southwest Airlines", carrierCode: "WN" },
  { name: "China Southern Airlines", carrierCode: "CZ" },
  { name: "China Eastern Airlines", carrierCode: "MU" },
  { name: "Air China", carrierCode: "CA" },
  { name: "Ryanair", carrierCode: "FR" },
  { name: "Lufthansa", carrierCode: "LH" },
  { name: "Turkish Airlines", carrierCode: "TK" },
  { name: "British Airways", carrierCode: "BA" },
  { name: "Air France", carrierCode: "AF" },
  { name: "Qantas Airways", carrierCode: "QF" },
  { name: "Emirates", carrierCode: "EK" },
  { name: "KLM Royal Dutch Airlines", carrierCode: "KL" },
  { name: "Japan Airlines", carrierCode: "JL" },
  { name: "Singapore Airlines", carrierCode: "SQ" },
  { name: "Cathay Pacific Airways", carrierCode: "CX" },
  { name: "Qatar Airways", carrierCode: "QR" },
  { name: "Virgin Atlantic Airways", carrierCode: "VS" },
  { name: "Air Canada", carrierCode: "AC" },
  { name: "ANA All Nippon Airways", carrierCode: "NH" },
  { name: "LATAM Airlines", carrierCode: "LA" },
  { name: "Etihad Airways", carrierCode: "EY" },
  { name: "Alitalia", carrierCode: "AZ" },
  { name: "Finnair", carrierCode: "AY" },
  { name: "Aeroflot", carrierCode: "SU" },
  { name: "Iberia", carrierCode: "IB" },
  { name: "Thai Airways", carrierCode: "TG" },
  { name: "IndiGo", carrierCode: "6E" },
  { name: "Swiss International Air Lines", carrierCode: "LX" },
  { name: "Saudi Arabian Airlines", carrierCode: "SV" },
  { name: "Korean Air", carrierCode: "KE" },
  { name: "EgyptAir", carrierCode: "MS" },
  { name: "Vietnam Airlines", carrierCode: "VN" },
  { name: "Norwegian Air Shuttle", carrierCode: "DY" },
  { name: "Brussels Airlines", carrierCode: "SN" },
  { name: "Aeromexico", carrierCode: "AM" },
  { name: "SAS Scandinavian Airlines", carrierCode: "SK" },
  { name: "Gulf Air", carrierCode: "GF" },
  { name: "Pakistan International Airlines", carrierCode: "PK" },
  { name: "Oman Air", carrierCode: "WY" },
  { name: "Royal Air Maroc", carrierCode: "AT" },
  { name: "Kenya Airways", carrierCode: "KQ" },
  { name: "Air India", carrierCode: "AI" },
  { name: "Austrian Airlines", carrierCode: "OS" },
  { name: "Cathay Dragon", carrierCode: "KA" },
  { name: "Air New Zealand", carrierCode: "NZ" },
  { name: "Philippine Airlines", carrierCode: "PR" },
  { name: "SriLankan Airlines", carrierCode: "UL" },
  { name: "Ethiopian Airlines", carrierCode: "ET" },
  { name: "TAP Air Portugal", carrierCode: "TP" },
  { name: "Hawaiian Airlines", carrierCode: "HA" },
  { name: "Air Mauritius", carrierCode: "MK" },
  { name: "GOL Linhas Aéreas Inteligentes", carrierCode: "G3" },
  { name: "Avianca", carrierCode: "AV" },
  { name: "Copa Airlines", carrierCode: "CM" },
  { name: "Azul Brazilian Airlines", carrierCode: "AD" },
  { name: "JetBlue Airways", carrierCode: "B6" },
  { name: "WestJet", carrierCode: "WS" },
  { name: "Vueling Airlines", carrierCode: "VY" },
  { name: "Norwegian Air International", carrierCode: "D8" },
  { name: "Wizz Air", carrierCode: "W6" },
  { name: "South African Airways", carrierCode: "SA" },
  { name: "Interjet", carrierCode: "4O" },
  { name: "LOT Polish Airlines", carrierCode: "LO" },
  { name: "Air Europa", carrierCode: "UX" },
  { name: "Uzbekistan Airways", carrierCode: "HY" },
  { name: "Air Astana", carrierCode: "KC" },
  { name: "El Al Israel Airlines", carrierCode: "LY" },
  { name: "TAAG Angola Airlines", carrierCode: "DT" },
  { name: "Bangkok Airways", carrierCode: "PG" },
  { name: "Air Serbia", carrierCode: "JU" },
  { name: "Royal Jordanian", carrierCode: "RJ" },
  { name: "Royal Brunei Airlines", carrierCode: "BI" },
  { name: "Azerbaijan Airlines", carrierCode: "J2" },
  { name: "Ukraine International Airlines", carrierCode: "PS" },
  { name: "Czech Airlines", carrierCode: "OK" },
  { name: "Air Seychelles", carrierCode: "HM" },
  { name: "EVA Air", carrierCode: "BR" },
  { name: "Malaysia Airlines", carrierCode: "MH" },
  { name: "Montenegro Airlines", carrierCode: "YM" },
  { name: "Ethiopian Airlines", carrierCode: "ET" },
  // Add more airlines as needed
];

// DEMO DATA
const typeAirlines = [
  {
    name: "Turkish Airlines",
    carrierCode: "TK",
  },
  {
    name: "British Airways",
    carrierCode: "BA",
  },
  {
    name: "American Airlines",
    carrierCode: "AA",
  },
  {
    name: "Emirates",
    carrierCode: "EK",
  },
  {
    name: "Singapore Airlines",
    carrierCode: "SQ",
  },
  {
    name: "Cathay Pacific",
    carrierCode: "CX",
  },
  {
    name: "KLM Royal Dutch Airlines",
    carrierCode: "KL",
  },
  {
    name: "Qatar Airways",
    carrierCode: "QR",
  },
  {
    name: "ANA All Nippon Airways",
    carrierCode: "NH",
  },
  {
    name: "Swiss International Air Lines",
    carrierCode: "LX",
  },
];

const stopPoints = [
  {
    name: "Direct",
  },
  {
    name: "1 stop",
  },
  {
    name: "2 stops",
  },
  {
    name: "Any number of stops",
  },
];

interface TabFiltersProps {
  setFilteredFlights: React.Dispatch<any[]>; // Adjust the type as needed
  flights: any[];
}
//

interface Segment {
  departure: {
    iataCode: string;
    terminal: null | string;
    at: string;
  };
  aircraft: {
    code: string;
    _320: null | string;
    _321: null | string;
    _333: null | string;
  };
  arrival: {
    iataCode: string;
    terminal: null | string;
    at: string;
  };
  blacklistedInEU: boolean;
  carrierCode: string;
  duration: string;
  id: string;
  number: string;
  numberOfStops: number;
  operating: {
    carrierCode: string;
  };
}
//
interface Itineraries {
  duration: string;
  segments: Segment[];
}

const TabFilters: React.FC<TabFiltersProps> = ({
  setFilteredFlights,
  flights,
}) => {
  const [typeOfAirlines, setTypeOfAirlines] = useState(typeAirlines);
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  //
  const [isOnSale, setIsOnSale] = useState(true);
  const [rangePrices, setRangePrices] = useState([100, 5000]);
  const [tripTimes, setTripTimes] = useState(72);
  const [stopPontsStates, setStopPontsStates] = useState<string[]>([]);
  const [airlinesStates, setAirlinesStates] = useState<string[]>([]);

  const getAirlineName = (carrierCode: string): string => {
    const airline = airlines.find(
      (airline) => airline.carrierCode === carrierCode
    );
    return airline ? airline.name : carrierCode;
  };

  function getHoursFromDuration(duration: string) {
    // Check if the duration string matches the format "PTXH" or "PTXHXM"
    const durationPattern = /^PT(\d{1,2})H(?:\d{1,2}M)?$/;
    const match = duration?.match(durationPattern);

    if (match) {
      // Extract hours from the matched group
      const hours = parseInt(match[1], 10); // Convert hours string to integer
      return hours;
    } else {
      // If the duration string doesn't match the pattern, return null or throw an error
      return null;
    }
  }

  function getHoursFromTime(time: string) {
    const date = new Date(time);
    return date.getHours();
  }

  //
  let [catTimes, setCatTimes] = useState({
    Outband: {
      Departure: [0, 24],
      Arrival: [0, 24],
    },
    Inbound: {
      Departure: [0, 24],
      Arrival: [0, 24],
    },
  });

  //
  const storedBoxData = localStorage.getItem("boxData");
  const parsedData = storedBoxData ? JSON.parse(storedBoxData) : null;
  //

  useEffect(() => {
    const filteredFlights = flights?.filter((flight) => {
      const outband: Itineraries = flight.itineraries[0];
      const inbound: Itineraries = flight.itineraries[1];
      const flightPrice = Math.round(flight.price.total);

      //Outband
      const outbandDuration = getHoursFromDuration(outband?.duration);
      const OutbandCarrierCodes = outband.segments.map(
        (segment) => segment.carrierCode
      );
      const OutbandStopPoints = outband.segments.length;
      const outbandDepTime = getHoursFromTime(outband.segments[0].departure.at);
      const outbandArivTime = getHoursFromTime(
        outband.segments[outband.segments.length - 1].arrival.at
      );

      //Inbound
      const inboundDuration = getHoursFromDuration(inbound?.duration);

      // Check if any selected airlines match
      if (airlinesStates.length > 0) {
        const selectedAirlines = airlinesStates.map((state) =>
          state.toUpperCase()
        ); // Convert selected airlines to uppercase for case-insensitive comparison

        const isOutboundSelected = OutbandCarrierCodes.some((code) =>
          selectedAirlines.includes(code.toUpperCase())
        );
        // const combinedCarrierCodes = Array.from(new Set(OutbandCarrierCodes.concat(inboundCarrierCodes)));
        // If neither inbound nor outbound contains selected airlines, skip this flight
        if (!isOutboundSelected) {
          return false; // Skip this flight if none of the selected airlines match
        }
        if (inbound) {
          const inboundCarrierCodes = inbound?.segments.map(
            (segment) => segment.carrierCode
          );
          const isInboundSelected = inboundCarrierCodes.some((code) =>
            selectedAirlines.includes(code.toUpperCase())
          );
          if (!isInboundSelected) {
            return false; // Skip this flight if none of the selected airlines match
          }
        }
      }

      const stopPointsMap = [
        "Direct",
        "1 stop",
        "2 stops",
        "Any number of stops",
      ];
      if (stopPontsStates.length > 0) {
        // Check if the number of stop points matches any selected option
        const isOutboundMatching =
          stopPontsStates.includes("Any number of stops") ||
          stopPontsStates.includes(stopPointsMap[OutbandStopPoints - 1]);
        // If neither outbound nor inbound matches any selected option, skip this flight
        if (!isOutboundMatching) {
          return false;
        }
        if (inbound) {
          const inboundStopPoints = inbound?.segments.length;
          const isInboundMatching =
            stopPontsStates.includes("Any number of stops") ||
            stopPontsStates.includes(stopPointsMap[inboundStopPoints - 1]);
          if (!isInboundMatching) {
            return false;
          }
        }
      }

      if (tripTimes) {
        if (outbandDuration) {
          if (tripTimes && outbandDuration > tripTimes) {
            return false;
          }
        }
        if (inboundDuration) {
          if (tripTimes && inboundDuration > tripTimes) {
            return false;
          }
        }
      }

      if (flightPrice) {
        if (!(flightPrice >= rangePrices[0] && flightPrice <= rangePrices[1])) {
          return false;
        }
      }

      if (catTimes) {
        const cartTimesOutDepTime = catTimes.Outband.Departure;
        const cartTimesOutArrtTime = catTimes.Outband.Arrival;
        const outDepChecker =
          outbandDepTime >= cartTimesOutDepTime[0] &&
          outbandDepTime <= cartTimesOutDepTime[1];
        const outArrChecker =
          outbandArivTime >= cartTimesOutArrtTime[0] &&
          outbandArivTime <= cartTimesOutArrtTime[1];

        if (inbound) {
          const inboundDepTime = getHoursFromTime(
            inbound?.segments[0].departure.at
          );
          const inboundArivTime = getHoursFromTime(
            inbound?.segments[inbound.segments?.length - 1].arrival.at
          );
          const cartTimesInbDepTime = catTimes.Inbound.Departure;
          const cartTimesInbArrTime = catTimes.Inbound.Arrival;
          const inbDepChecker =
            inboundDepTime >= cartTimesInbDepTime[0] &&
            inboundDepTime <= cartTimesInbDepTime[1];
          const inbArrChecker =
            inboundArivTime >= cartTimesInbArrTime[0] &&
            inboundArivTime <= cartTimesInbArrTime[1];

          if (!(inbDepChecker && inbArrChecker)) {
            return false;
          }
        }

        if (!(outDepChecker && outArrChecker)) {
          return false;
        }
      }

      return true;
    });

    setFilteredFlights(filteredFlights);
  }, [airlinesStates, stopPontsStates, tripTimes, rangePrices, catTimes]);

  //

  useEffect(() => {
    if (!flights) return;

    // Initialize a Set to store unique carrier codes
    const allCarrierCodes: Set<string> = new Set();

    // Loop through each flight
    flights.forEach((flight) => {
      const outband = flight.itineraries[0];
      const inbound = flight.itineraries[1];

      // Extract carrier codes from outband segments and add them to allCarrierCodes set
      outband?.segments.forEach((segment: any) => {
        allCarrierCodes.add(segment.carrierCode);
      });

      // Extract carrier codes from inbound segments and add them to allCarrierCodes set
      inbound?.segments.forEach((segment: any) => {
        allCarrierCodes.add(segment.carrierCode);
      });
    });

    // Convert the set back to an array if needed
    const uniqueCarrierCodes = Array.from(allCarrierCodes).map(
      (carrierCode) => ({
        name: getAirlineName(carrierCode),
        carrierCode: carrierCode,
      })
    );

    // Now uniqueCarrierCodes array contains all unique carrier codes from all flights
    setTypeOfAirlines(uniqueCarrierCodes);

    // If needed, you can set this array to state or perform any other operations
  }, [flights]);

  //
  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  //
  const handleChangeStopPoint = (checked: boolean, name: string) => {
    checked
      ? setStopPontsStates([...stopPontsStates, name])
      : setStopPontsStates(stopPontsStates.filter((i) => i !== name));
  };

  const handleChangeAirlines = (checked: boolean, name: string) => {
    if (name === "All Airlines") {
      // If "All Airlines" is selected, select/deselect all options accordingly
      const allAirlinesCodes = typeOfAirlines.map(
        (option) => option.carrierCode
      );
      setAirlinesStates(checked ? allAirlinesCodes : []);
    } else {
      // If any other option is selected/deselected
      const updatedAirlinesStates = checked
        ? [...airlinesStates, name]
        : airlinesStates.filter((i) => i !== name);
      // If "All Airlines" was previously selected and now any other option is deselected, deselect "All Airlines"
      if (airlinesStates.includes("All Airlines") && !checked) {
        const remainingAirlines = updatedAirlinesStates.filter(
          (i) => i !== "All Airlines"
        );
        setAirlinesStates(remainingAirlines);
      } else {
        setAirlinesStates(updatedAirlinesStates);
      }
    }
  };

  //

  const renderXClear = () => {
    return (
      <span className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderTabsTimeFlightTab = () => {
    return (
      <div>
        <Tab.Group>
          <Tab.List className="flex p-1 space-x-1 bg-primary-900/10 rounded-xl">
            {Object.keys(catTimes).map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  `w-full py-2.5 text-sm leading-5 font-medium text-primary-700 dark:text-primary-400 rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60 ${
                    selected
                      ? "bg-white dark:bg-neutral-800 shadow"
                      : " hover:bg-white/[0.15] dark:hover:bg-neutral-800"
                  }`
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2">
            {Object.values(catTimes).map((posts, idx) => {
              return (
                <Tab.Panel
                  key={idx}
                  className={
                    "bg-neutral-50 dark:bg-neutral-900 rounded-xl p-3 space-y-8 focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60"
                  }
                >
                  <span className=" text-neutral-6000 dark:text-neutral-300 text-sm">
                    {idx
                      ? ` ${parsedData?.toValue?.name} to ${parsedData?.fromValue?.name}`
                      : ` ${parsedData?.fromValue?.name} to ${parsedData?.toValue?.name}`}
                  </span>
                  <div></div>
                  <div className="space-y-3">
                    <div className="flex space-x-2">
                      <i className="text-lg las la-plane-departure"></i>
                      <span className="text-xs">Departure time:</span>
                      <span className="text-xs text-primary-500 dark:text-primary-400">
                        {posts.Departure[0]}:00 - {posts.Departure[1]}
                        :00
                      </span>
                    </div>
                    <Slider
                      range
                      min={0}
                      max={24}
                      defaultValue={posts.Departure}
                      onChange={(val) =>
                        setCatTimes((catTimes) =>
                          !idx
                            ? {
                                ...catTimes,
                                Outband: {
                                  ...posts,
                                  Departure: val as [number, number],
                                },
                              }
                            : {
                                ...catTimes,
                                Inbound: {
                                  ...posts,
                                  Departure: val as [number, number],
                                },
                              }
                        )
                      }
                      allowCross={false}
                    />
                  </div>
                  <div className="space-y-3">
                    <div className="flex space-x-2">
                      <i className="text-lg las la-plane-arrival"></i>
                      <span className="text-xs">Arrival time:</span>
                      <span className="text-xs text-primary-500 dark:text-primary-400">
                        {posts.Arrival[0]}:00 - {posts.Arrival[1]}:00
                      </span>
                    </div>
                    <Slider
                      range
                      min={0}
                      max={24}
                      defaultValue={posts.Arrival}
                      onChange={(val) =>
                        setCatTimes((catTimes) =>
                          !idx
                            ? {
                                ...catTimes,
                                Outband: {
                                  ...posts,
                                  Arrival: val as [number, number],
                                },
                              }
                            : {
                                ...catTimes,
                                Inbound: {
                                  ...posts,
                                  Arrival: val as [number, number],
                                },
                              }
                        )
                      }
                      allowCross={false}
                    />
                  </div>
                </Tab.Panel>
              );
            })}
          </Tab.Panels>
        </Tab.Group>
      </div>
    );
  };

  const renderTabsTypeOfAirlines = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none
               ${open ? "!border-primary-500 " : ""}
                ${
                  !!airlinesStates.length
                    ? "!border-primary-500 bg-primary-50"
                    : ""
                }
                `}
            >
              <span>Airlines</span>
              {!airlinesStates.length ? (
                <i className="las la-angle-down ml-2"></i>
              ) : (
                <span onClick={() => setAirlinesStates([])}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <Checkbox
                      name="All Airlines"
                      label="All Airlines"
                      defaultChecked={airlinesStates.includes("All Airlines")}
                      onChange={(checked) =>
                        handleChangeAirlines(checked, "All Airlines")
                      }
                    />
                    <hr />
                    {typeOfAirlines.map((item) => (
                      <div key={item.carrierCode} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={airlinesStates.includes(
                            item.carrierCode
                          )}
                          onChange={(checked) =>
                            handleChangeAirlines(checked, item.carrierCode)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setAirlinesStates([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsStopPoints = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none 
              ${open ? "!border-primary-500 " : ""}
                ${
                  !!stopPontsStates.length
                    ? "!border-primary-500 bg-primary-50"
                    : ""
                }
                `}
            >
              <span>Stop points</span>
              {!stopPontsStates.length ? (
                <i className="las la-angle-down ml-2"></i>
              ) : (
                <span onClick={() => setStopPontsStates([])}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {stopPoints.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={stopPontsStates.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeStopPoint(checked, item.name)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setStopPontsStates([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTimeFlight = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Flight time</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900   border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {renderTabsTimeFlightTab()}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setCatTimes({
                          Outband: {
                            Departure: [0, 24],
                            Arrival: [0, 24],
                          },
                          Inbound: {
                            Departure: [0, 24],
                            Arrival: [0, 24],
                          },
                        });
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTripTime = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none 
              ${open ? "!border-primary-500 " : ""}
                ${tripTimes !== 72 ? "!border-primary-500 bg-primary-50" : ""}
                `}
            >
              <span>less than {tripTimes} hours</span>
              {tripTimes !== 72 && renderXClear()}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <div className="font-medium">
                        Trip time:
                        <span className="text-sm font-normal ml-1 text-primary-500">{` <${tripTimes} hours`}</span>
                      </div>

                      <Slider
                        min={1}
                        max={72}
                        defaultValue={tripTimes}
                        onChange={(e) => setTripTimes(e as number)}
                      />
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setTripTimes(72);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsPriceRage = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border   ${
                rangePrices[0] !== 100 || rangePrices[1] !== 5000
                  ? "border-primary-500 bg-primary-50 text-primary-700 focus:outline-none"
                  : ""
              }`}
            >
              <span>
                {`$${convertNumbThousand(
                  rangePrices[0]
                )} - $${convertNumbThousand(rangePrices[1])}`}{" "}
              </span>
              {(rangePrices[0] !== 100 || rangePrices[1] !== 5000) &&
                renderXClear()}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">Price per person</span>
                      <Slider
                        range
                        min={100}
                        max={5000}
                        defaultValue={[rangePrices[0], rangePrices[1]]}
                        allowCross={false}
                        onChange={(e) => setRangePrices(e as number[])}
                      />
                    </div>

                    <div className="flex justify-between space-x-5">
                      <div>
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Min price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">
                              $
                            </span>
                          </div>
                          <input
                            type="text"
                            name="minPrice"
                            disabled
                            id="minPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={rangePrices[0]}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Max price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">
                              $
                            </span>
                          </div>
                          <input
                            type="text"
                            disabled
                            name="maxPrice"
                            id="maxPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={rangePrices[1]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setRangePrices([100, 5000]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabOnSale = () => {
    return (
      <div
        className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all ${
          isOnSale
            ? "border-primary-500 bg-primary-50 text-primary-700"
            : "border-neutral-300 dark:border-neutral-700"
        }`}
        onClick={() => setIsOnSale(!isOnSale)}
      >
        <span>On sale</span>
        {isOnSale && renderXClear()}
      </div>
    );
  };

  const renderMoreFilterItem = (
    data: {
      name: string;
      description?: string;
      defaultChecked?: boolean;
    }[]
  ) => {
    const list1 = data.filter((_, i) => i < data.length / 2);
    const list2 = data.filter((_, i) => i >= data.length / 2);
    return (
      <div className="grid grid-cols-2 gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.description}
              label={item.name}
              defaultChecked={!!item.defaultChecked}
            />
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.description}
              label={item.name}
              defaultChecked={!!item.defaultChecked}
            />
          ))}
        </div>
      </div>
    );
  };

  // FOR RESPONSIVE MOBILE
  const renderTabMobileFilter = () => {
    return (
      <div>
        <div
          className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-700 focus:outline-none cursor-pointer`}
          onClick={openModalMoreFilter}
        >
          <span>
            <span className="hidden sm:inline">Flights</span> filters 
          </span>
          {renderXClear()}
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Flight filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-4 md:px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Airlines</h3>
                        <div className="mt-6 relative ">
                          <Grid container spacing={2}>
                            {typeOfAirlines.map((item) => (
                              <Grid item xs={4}>
                                <div key={item.carrierCode} className="">
                                  <Checkbox
                                    name={item.name}
                                    label={item.name}
                                    defaultChecked={airlinesStates.includes(
                                      item.carrierCode
                                    )}
                                    onChange={(checked) =>
                                      handleChangeAirlines(
                                        checked,
                                        item.carrierCode
                                      )
                                    }
                                  />
                                </div>
                              </Grid>
                            ))}
                          </Grid>
                        </div>
                      </div>
                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Stop points</h3>
                        <div className="mt-6 relative ">
                          <Grid container spacing={2}>
                            {stopPoints.map((item) => (
                              <Grid item xs={6}>
                                <div key={item.name} className="">
                                  <Checkbox
                                    name={item.name}
                                    label={item.name}
                                    defaultChecked={stopPontsStates.includes(
                                      item.name
                                    )}
                                    onChange={(checked) =>
                                      handleChangeStopPoint(checked, item.name)
                                    }
                                  />
                                </div>
                              </Grid>
                            ))}
                          </Grid>
                        </div>
                      </div>

                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Range Prices</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-8">
                            <div className="space-y-5">
                              <Slider
                                range
                                min={100}
                                max={5000}
                                defaultValue={[rangePrices[0], rangePrices[1]]}
                                allowCross={false}
                                onChange={(e) => setRangePrices(e as number[])}
                              />
                            </div>

                            <div className="flex justify-between space-x-5">
                              <div>
                                <label
                                  htmlFor="minPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Min price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name="minPrice"
                                    disabled
                                    id="minPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={rangePrices[0]}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="maxPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Max price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    disabled
                                    name="maxPrice"
                                    id="maxPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={rangePrices[1]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">
                          trip times
                          <span className="text-sm font-normal ml-1 text-primary-500">{` <${tripTimes} hours`}</span>
                        </h3>
                        <div className="mt-6 relative ">
                      <Slider
                        min={1}
                        max={72}
                        defaultValue={tripTimes}
                        onChange={(e) => setTripTimes(e as number)}
                      />
                        </div>
                      </div>

                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Flight times</h3>
                        <div className="relative flex flex-col py-5 space-y-5">
                          {renderTabsTimeFlightTab()}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-4 sm:p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={()=>{
                        setAirlinesStates([])
                        setStopPontsStates([])
                        setCatTimes({
                          Outband: {
                            Departure: [0, 24],
                            Arrival: [0, 24],
                          },
                          Inbound: {
                            Departure: [0, 24],
                            Arrival: [0, 24],
                          },
                        });
                        setTripTimes(72);
                        setRangePrices([100, 5000]);
                        closeModalMoreFilter()
                      }
                      }
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={closeModalMoreFilter}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className="flex lg:space-x-4">
      {/* FOR DESKTOP */}
      <div className="hidden lg:flex space-x-4">
        {renderTabsTypeOfAirlines()}
        {renderTabsTripTime()}
        {renderTabsStopPoints()}
        {renderTabsPriceRage()}
        {renderTabsTimeFlight()}
      </div>

      {/* FOR RESPONSIVE MOBILE */}
      <div className="flex lg:hidden space-x-4">{renderTabMobileFilter()}</div>
    </div>
  );
};

export default TabFilters;
