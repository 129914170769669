import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ticket from "../../images/tickets.png";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

function createData(
  name: string,
  standard: boolean,
  premium: boolean,
  premiumPlus: boolean,
  text: any
) {
  return {
    name,
    standard,
    premium,
    premiumPlus,
    text,
  };
}

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="large"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" onClick={() => setOpen(!open)} style={{cursor:'pointer'}}>
          <Typography color='primary'>{row.name}</Typography>
        </TableCell>
        <TableCell>{row.standard ? <CheckIcon color="success"/> : <CloseIcon color="error"/>}</TableCell> 
        <TableCell>{row.premium ? <CheckIcon color="success"/> : <CloseIcon color="error"/>}</TableCell>
        <TableCell>{row.premiumPlus ? <CheckIcon color="success"/> : <CloseIcon color="error"/>}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} p={3}>
              <Typography variant="body1" gutterBottom component="div">
                {row.text}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const rows = [
  createData(
    "premium support ",
    true,
    true,
    true,
    <p>Save up to 40 EUR at no cost for extra services</p>
  ),
  createData(
    "Airhelp Plus Delay Compensation",
    true,
    true,
    true,
    <p>
      <p>
        * Easily claim up to 600 EUR compensation for flight delay, cancellation,
        or overbooking.
      </p>
      <p>
        * Get up to 6000 EUR back for delayed costs such as lost baggage or missed
        reservations.
      </p>
      <p>* AirHelp takes care of the entire process</p>
    </p>
  ),
  createData(
    "Booking refrence by text message",
    false,
    true,
    true,
    <p>
      <p>* Receive your booking numbers via SMS for easy access.</p>
      <p>
        * Save your travel details offline n sms. An easy way to always have the
        booking number at hand.
      </p>
    </p>
  ),
  createData(
    "Flight updates by SMS",
    false,
    false,
    true,
    <p>
      <p>
        * Access to all information at once: booking and flight number, departure
        schedule and more available via SMS
      </p>
      <p>
        * Get live updates on your flight, including gate and/or last-minute
        schedule changes
      </p>
    </p>
  ),
];

export default function CollapsibleTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <img src={ticket} width={50} alt="" />
            </TableCell>
            <TableCell>Standard</TableCell>
            <TableCell>Premium</TableCell>
            <TableCell>Premium+</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
