import DatePicker from "react-datepicker";
import React, { FC, useEffect, useState } from "react";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { addDays } from "date-fns";

export interface StayDatesRangeInputProps {
  className?: string;
  selectsRange?: boolean;
  setDepartureDate: (date: Date | null) => void;
  setReturnDate?: (date: Date | null) => void;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "",
  setDepartureDate,
  setReturnDate,
  selectsRange=true,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);


  useEffect(() => {
    const storedLocalData = localStorage.getItem("boxData");
    const parsedData = storedLocalData ? JSON.parse(storedLocalData) : null;

    const initialDepartureDate = parsedData?.departureDate
      ? new Date(parsedData.departureDate)
      : new Date();
    const initialReturnDate = parsedData?.returnDate
      ? new Date(parsedData.returnDate)
      : addDays(new Date(), 15);

    setStartDate(initialDepartureDate);
    setEndDate(initialReturnDate);
  }, []);

  const onChangeRangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (selectsRange) {
      if (setDepartureDate && setReturnDate) {
        setDepartureDate(start);
        setReturnDate(end);
      }
    } else {
      if (setDepartureDate) {
        setDepartureDate(start);
      }
    }
  };

  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {` When's your trip?`}
        </span>
      </div>
      <div
        className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className} `}
      >
        {selectsRange ? (
          <DatePicker
            selected={startDate}
            onChange={onChangeRangeDate}
            minDate={new Date()}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            monthsShown={2}
            showPopperArrow={false}
            inline
            renderCustomHeader={(p) => (
              <DatePickerCustomHeaderTwoMonth {...p} />
            )}
            renderDayContents={(day, date) => (
              <DatePickerCustomDay dayOfMonth={day} date={date} />
            )}
          />
        ) : (
          <DatePicker
            selected={startDate}
            minDate={new Date()}
            onChange={(date) => {
              setStartDate(date);
             setDepartureDate(date);
            }}
            monthsShown={2}
            showPopperArrow={false}
            inline
            renderCustomHeader={(p) => (
              <DatePickerCustomHeaderTwoMonth {...p} />
            )}
            renderDayContents={(day, date) => (
              <DatePickerCustomDay dayOfMonth={day} date={date} />
            )}
          />
        )}
      </div>
    </div>
  );
};

export default StayDatesRangeInput;
