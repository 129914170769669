import React, { FC, useEffect, useState } from "react";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import FlightCard, { FlightCardProps } from "components/FlightCard/FlightCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import FlightsApi from "apis/FlightsApi";
import Pagination from "shared/Pagination/Pagination";
import PaginationWithResultsInfo from "./PaginationWithResultsInfo";
import Stack from "@mui/material/Stack";
import { Box, Typography, duration } from "@mui/material";

function timeStringToMinutes(timeString: string) {

  // Check if the string starts with "PT"
  if (timeString?.startsWith("PT")) {
    // Extract hours and minutes using regular expressions
    const hoursRegex = /PT(\d+)H/;
    const minutesRegex = /H(\d+)M/;
    const hoursMatch = timeString.match(hoursRegex);
    const minutesMatch = timeString.match(minutesRegex);

    // Initialize hours and minutes
    let hours = 0;
    let minutes = 0;

    // If hours are provided, extract and convert to integer
    if (hoursMatch) {
      hours = parseInt(hoursMatch[1]);
    }

    // If minutes are provided, extract and convert to integer
    if (minutesMatch) {
      minutes = parseInt(minutesMatch[1]);
    }

    // Calculate the total minutes
    const totalMinutes = hours * 60 + minutes;
    return totalMinutes;
  }

  // Return null if the input format is incorrect
  return 0;
}
function formatDuration(duration: string) {
  // Check if the duration starts with "PT"
  if (duration?.startsWith("PT")) {
    // Extract hours and minutes using regular expressions
    const hoursRegex = /PT(\d+)H/;
    const minutesRegex = /H(\d+)M/;
    const hoursMatch = duration.match(hoursRegex);
    const minutesMatch = duration.match(minutesRegex);

    // Initialize hours and minutes
    let formattedHours = 0;
    let formattedMinutes = 0;

    // If hours are provided, extract and convert to integer
    if (hoursMatch) {
      formattedHours = parseInt(hoursMatch[1]);
    }

    // If minutes are provided, extract and convert to integer
    if (minutesMatch) {
      formattedMinutes = parseInt(minutesMatch[1]);
    }

    const hoursText =
      formattedHours > 0
        ? `${formattedHours}h`
        : "";
    const minutesText =
      formattedMinutes > 0
        ? `${formattedMinutes}m`
        : "";

    if (hoursText && minutesText) {
      return `${hoursText} ${minutesText}`;
    } else if (hoursText) {
      return hoursText;
    } else if (minutesText) {
      return minutesText;
    }
  }

  // Return "test" if the input format is incorrect
  return "test";
}
export interface SectionGridFilterCardProps {
  className?: string;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  const [typeFilter, setTypeFilter] = React.useState("best");
  const [bestFilter, setBestFilter] = React.useState({
    price: "" ,
    duration: "",
  });
  const [cheapestFilter, setCheapestFilter] = React.useState({
    price: "",
    duration: "",
  });
  const [fastestFilter, setFastestFilter] = React.useState({
    price: "",
    duration: "",
  });

  const storedBoxData = localStorage.getItem("boxData");
  const storedFormData = localStorage.getItem("formData");
  const [parsedBoxData, setParsedBoxData] = useState<any>(null);

  const [flights, setFlights] = useState<any[]>([]);
  const [filteredFlights, setFilteredFlights] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);


  const handleTypeFilter = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (newAlignment !== null) {
      setTypeFilter(newAlignment);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const parsedFormData = storedFormData ? JSON.parse(storedFormData) : null;

      if (parsedFormData) {
        setLoading(true);
        try {
          const response = await FlightsApi.getFlights(parsedFormData);
          if (response.success) {
            setFlights(response.data.data.data);
            setFilteredFlights(response.data.data.data);
          }
        } catch (error) {
          console.error("Error fetching flights:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    setParsedBoxData(storedBoxData ? JSON.parse(storedBoxData) : null);
    fetchData(); // Call the async function immediately
  }, []); // Add formData as a dependency to useEffect

  useEffect(() => {
    const fastestResult = flights.slice().sort(sortFastest);
    const cheapestResult = flights.slice().sort(sortCheapest);
    const bestResult = flights.slice().sort(sortBest);
    const calculateFilterProperties = () => {
      let bestFlight: any = "";
      let cheapestFlight: any = "";
      let fastestFlight: any = "";

      bestFlight = {
        price: Math.max(
          ...filteredFlights.map((obj) => Math.round(obj.price.total))
        ),
        duration: formatDuration(bestResult[0]?.itineraries[0].duration),
      };
      cheapestFlight = {
        price: Math.min(
          ...filteredFlights.map((obj) => Math.round(obj.price.total))
        ),
        duration:formatDuration(cheapestResult[0]?.itineraries[0].duration),
      };

      fastestFlight={
        price:Math.round(fastestResult[0]?.price.total),
        duration:formatDuration(fastestResult[0]?.itineraries[0].duration)
      }
      setBestFilter(bestFlight);
      setCheapestFilter(cheapestFlight);
      setFastestFilter(fastestFlight);
    };

    // Comparison function for sorting from smallest to largest
    function sortBest(obj1: any, obj2: any) {
      return Math.round(obj2.price.total) - Math.round(obj1.price.total);
    }
    // Comparison function for sorting from smallest to largest
    function sortCheapest(obj1: any, obj2: any) {
      return Math.round(obj1.price.total) - Math.round(obj2.price.total);
    }

    function sortFastest(obj1: any, obj2: any) {
      const duration1 = timeStringToMinutes(obj1.itineraries[0].duration);
      const duration2 = timeStringToMinutes(obj2.itineraries[0].duration);
      return duration1 - duration2;
    }


    if (typeFilter === "best") {

      setFilteredFlights(bestResult);
    }
    if (typeFilter === "cheapest") {

      setFilteredFlights(cheapestResult);
    }
    if (typeFilter === "fastest") {
      setFilteredFlights(fastestResult);
    }
    calculateFilterProperties();
  }, [typeFilter,flights]);

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading={`${parsedBoxData?.fromValue?.address?.cityName} →
        ${parsedBoxData?.toValue?.address?.cityName}`}
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            {filteredFlights.length} flights
            <span className="mx-2">·</span>
            {parsedBoxData?.dropOffLocationType === "return"
              ? "round trip"
              : "one-way"}
            <span className="mx-2">·</span>
            {parsedBoxData?.guestAdultsInputValue +
              parsedBoxData?.guestChildrenInputValue +
              parsedBoxData?.guestInfantsInputValue}{" "}
            Passengers
          </span>
        }
      />
      <div className="mb-8 lg:mb-11">
        <TabFilters setFilteredFlights={setFilteredFlights} flights={flights} />
      </div>
      <div className="mb-8 lg:mb-11">
        <Stack  direction={{ xs: 'column', md: 'row' }} spacing={2}>
          <Stack
            className={`w-full  py-4 px-4 flex items-center rounded-lg font-medium text-md cursor-pointer mr-2 my-1 sm:mr-3 ${
              typeFilter === "best"
                ? "bg-primary-6000  shadow-black/10 shadow-lg text-white"
                : "border border-neutral-300 dark:border-neutral-700"
            }`}
            style={{ textAlign: "left" }}
            spacing={1}
            alignItems={"self-start"}
            onClick={(e) => handleTypeFilter(e, "best")}
          >
            <Typography style={{ fontWeight: "bold" }}>Best</Typography>
            {!loading && (
              <Stack direction={"row"} spacing={1}>
                <Typography component="text" variant="subtitle1">
                  {bestFilter.price}€
                </Typography>
                <Typography>({bestFilter.duration})</Typography>
              </Stack>
            )}
          </Stack>

          <Stack
            className={`w-full  py-4 px-4 flex items-center rounded-lg font-medium text-md cursor-pointer mr-2 my-1 sm:mr-3 ${
              typeFilter === "cheapest"
                ? "bg-primary-6000 text-white shadow-black/10 shadow-lg"
                : "border border-neutral-300 dark:border-neutral-700"
            }`}
            style={{ textAlign: "left" }}
            spacing={1}
            alignItems={"self-start"}
            onClick={(e) => handleTypeFilter(e, "cheapest")}
          >
            <Typography style={{ fontWeight: "bold" }}>Cheapest</Typography>
            {!loading && (
              <Stack direction={"row"} spacing={1}>
                <Typography component="text" variant="subtitle1">
                  {cheapestFilter.price} €
                </Typography>
                <Typography>( {cheapestFilter.duration} )</Typography>
              </Stack>
            )}
          </Stack>
          <Stack
            className={` w-full py-4 px-4 flex items-center rounded-lg font-medium text-md cursor-pointer mr-2 my-1 sm:mr-3 ${
              typeFilter === "fastest"
                ? "bg-primary-6000 text-white shadow-black/10 shadow-lg"
                : "border border-neutral-300 dark:border-neutral-700"
            }`}
            style={{ textAlign: "left" }}
            spacing={1}
            alignItems={"self-start"}
            onClick={(e) => handleTypeFilter(e, "fastest")}
          >
            <Typography style={{ fontWeight: "bold" }}>Fastest</Typography>
            {!loading && (
              <Stack direction={"row"} spacing={1}>
                <Typography component="text" variant="subtitle1">
                  {fastestFilter.price} €
                </Typography>
                <Typography>({fastestFilter.duration})</Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </div>
      <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl">
        {/* {DEMO_DATA.map((item, index) => (
          <FlightCard defaultOpen={!index} key={index} data={item} />
        ))} */}
        {loading ? (
          //   <div className="flex mt-12 justify-center items-center">
          //   <ButtonPrimary loading>Show more</ButtonPrimary>
          // </div>
          [...Array(5)].map((_, index) => (
            <div
              key={index}
              className=" shadow rounded-md p-4 max-w w-full mx-auto my-4 "
            >
              <div className="animate-pulse flex space-x-4 py-15">
                <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                <div className="flex-1 space-y-6 py-1">
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                    </div>
                    <div className="h-2 bg-slate-700 rounded"></div>
                  </div>
                </div>
                <div className="flex-1 space-y-6 py-1">
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                    </div>
                    <div className="h-2 bg-slate-700 rounded"></div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : filteredFlights.length >= 1 ? (
          <>
            {filteredFlights?.map((flight, index) => (
              <div key={index} className="bg-white shadow rounded-lg p-4">
                {/* Render FlightCard with appropriate data */}
                <FlightCard data={flight} />
              </div>
            ))}
            {/* <PaginationWithResultsInfo /> */}
          </>
        ) : (
          <span>no flights found</span>
        )}
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
