import {
  MapPinIcon,
  MagnifyingGlassIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import AirportsApi from "apis/AirportsApi";
import React, { useState, useEffect, useRef, FC } from "react";

export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  setFrom?: (value: Airport | null) => void;
  setTo?: (value: Airport | null) => void;
  fromValue?: Airport | null;
  toValue?: Airport | null;
}
export interface Airport {
  id: string;
  name: string;
  iataCode: string;
  // Add other properties if needed
}

const airportsList: Airport[] = [
  {
    id: "1",
    name: "Hamptons, Suffolk County, NY",
    iataCode: "SAW",
  },
];

interface Props {
  onClick?: () => void;
  onChange?: (value: string) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string;
  autoFocus?: boolean;
  setFrom?: (value: Airport | null) => void;
  setTo?: (value: Airport | null) => void;
  fromValue?: Airport | null;
  toValue?: Airport | null;
}

const LocationInput: FC<Props> = ({
  autoFocus = false,
  onChange = () => {},
  setFrom, // Destructure 'setFrom' from props
  setTo, // Destructure 'setTo' from props
  fromValue,
  toValue,
  className = "",
  defaultValue = "United States",
  headingText = "Where to?",
}) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<Airport | null>(null);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [airports, setAirports] = useState<Airport[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showPopover, setShowPopover] = useState(false); 

  useEffect(() => {
    const storedLocalData = localStorage.getItem("boxData");
    const parsedData = storedLocalData ? JSON.parse(storedLocalData) : null;

    const initialFromVAlue = parsedData?.fromValue?.name;
    const initialToValue = parsedData?.toValue?.name;

    if (setFrom) setSearchTerm(initialFromVAlue);
    if (setTo) setSearchTerm(initialToValue);
  }, []);

  const handleSelectLocation = (item: Airport) => {
    setValue(item);
    setShowPopover(false);
    setSearchTerm(item.name);
    if (setFrom) setFrom(item); // Call 'setFrom' if it exists
    if (setTo) setTo(item); // Call 'setTo' if it exists
  };

  const handleGetAirports = async (term: string) => {
    setLoading(true); // Set loading to true when fetching starts
    try {
      const response = await AirportsApi.getAllAirports(term);
      setAirports(response.data);
    } catch (error) {
      // Handle errors
    } finally {
      setLoading(false); // Set loading to false when fetching finishes
    }
  };
  useEffect(() => {
    if (searchTerm) {
      handleGetAirports(searchTerm);
    }
  }, [searchTerm]);
  // useEffect(() => {
  //   setShowPopover(autoFocus);
  // }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };



  const renderSearchValues = ({
    heading,
    items,
  }: {
    heading: string;
    items: string[];
  }) => {
    return (
      <>
        <p className="block font-semibold text-base">
          {heading || "Destinations"}
        </p>
        <div className="mt-3">
          {items.map((item) => {
            return (
              <div
                className="py-2 mb-1 flex items-center space-x-3 text-sm"
                key={item}
              >
                <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                <span className="">{item}</span>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5"         
      >
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={"Search destinations"}
            onClick={() => setShowPopover(true)}
            value={searchTerm}
            autoFocus={showPopover}
            onChange={(e) => {
              setSearchTerm(e.currentTarget.value);
            }}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          <>
          {searchTerm && showPopover &&
          (
          loading ? (
            <div className="  rounded-md p-4 max-w-sm w-full mx-auto">
              Loading...
            </div>
          ) : (
            airports?.map((item: Airport) => (
              <span
                onClick={() => handleSelectLocation(item)}
                key={item.id}
                className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
              >
                <span className="block text-neutral-400">
                  <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                </span>
                <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                  {item.name}
                </span>
              </span>
            ))
          )
        )
          }
            {}
          </>
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
